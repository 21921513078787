import React from 'react';
import FlexImages from './FlexImages/FlexImages';

const Content = () => (
    <React.Fragment>
        <p>Witbe's virtual NOC: the Remote Eye Controller, gives video service providers eyes and ears on how their streams perform on real devices in the lab and field.</p>
        <h2>What is a Network Operations Center?</h2>
        <p>
            Most video service providers rely on a
            {' '}
            <b>Network Operations Center</b>
            , commonly known as a NOC. A
            {' '}
            <b>Network Operations Center</b>
            {' '}
            is a centralized location where a Video Operations team can monitor their service as it's being broadcast out to customers.
        </p>
        <p>A NOC displays the source stream for every live channel or video asset, so the team can see if there are any errors being broadcasted out. It’s essential, but it doesn't paint the full picture – since what gets sent out isn't always what gets received by viewers.</p>
        <h2>What does Witbe's technology transforms the traditional NOC?</h2>
        <p>
            <a href='/technology/' target='_blank'>
                Witbe's technology
            </a>
            {' allows providers to test and monitor how their service runs on real devices and networks. These '}
            <a href='/technology/supported-devices/' target='_blank'>
                include
            </a>
            {' Set-Top Boxes, Smart TVs, and the same mobile devices we use every day.'}
        </p>
        <p>
            It's fundamental to what we do at Witbe, since it empowers providers to measure the viewing Quality of Experience that actually reaches their customers.
            It's also important since plenty of errors that affect videos on real devices cannot be observed anywhere else, and would otherwise go unresolved.
        </p>
        <figure>
            <img src='/articles/2024-11-04-noc/noc_asset-articles-content-landscape1-min.jpg' alt='Virtual NOC mosaic interface compiling your real local streams' />
        </figure>
        <h2>How can you remotely control your real devices with Witbe's virtual NOC?</h2>
        <p>
            Witbe's
            {' '}
            <a href='/technology/' target='_blank'>
                Remote Eye Controller
            </a>
            {', part of the comprehensive '}
            <a href='/technology/' target='_blank'>
                Witbe Software Suite
            </a>
            , is a virtual workspace where teams can monitor their video service from anywhere, at any time.
        </p>
        <p>
            {'The REC takes the live video streams from real devices connected to a '}
            <a href='/products/witbox/' target='_blank'>
                Witbox
            </a>
            {' '}
            and compiles them online in a mosaic, where team members can take control of any device in real time to test an asset or monitor the performance of an app.
        </p>
        <p>
            The REC is accessible from any web browser, meaning team members can control real devices running in the field or the lab from their own laptop, tablet, or mobile device.
        </p>
        <figure>
            <img src='/articles/2024-11-04-noc/noc_asset-articles-content-landscape2-min.jpg' alt='Virtual NOC mosaic interface in iPad' />
        </figure>
        <h2>What are the advantages of a NOC?</h2>
        <p>
            <b>The REC is Witbe's virtual NOC</b>
            , an online workspace where teams can
            {' '}
            <a href='/technology/video-service-monitoring/' target='_blank'>
                monitor and supervise their video service
            </a>
            {' '}
            in real time. When paired with an in-house NOC, video service providers can efficiently observe any differences between what is sent out from their source broadcast and what is received on real devices.
        </p>
        <p>
            This is key for quick error resolution, which the REC also supports with live service alerts and KPIs displayed directly in the interface. Team members can also quickly record evidences in the REC and share them to escalate and resolve errors.
        </p>
        <FlexImages
            leftPicture={{
                img: '/articles/2024-11-04-noc/noc_asset-articles-content-landscape4.jpg',
                alt: 'A clip taken from a source video in the REC',
            }}
            rightPicture={{
                img: '/articles/2024-11-04-noc/noc_asset-articles-content-landscape3-min.jpg',
                alt: 'Virtual NOC mosaic interface',
            }}
        />
        <h3>Learn more about real-time video testing and monitoring</h3>
        <p>
            With a virtual NOC, video service providers can monitor their service on real devices, immediately catch errors to resolve them efficiently, and deliver the best Quality of Experience possible.
        </p>
        <p>
            To learn more about Witbe's virtual NOC and our video service monitoring technology,
            {' '}
            <a href='/contact-us/' target='_blank' alt='Contact us'>
                contact us today
            </a>
        </p>
    </React.Fragment>
);
export default Content;
