import React from 'react';

const Content = () => (
    <React.Fragment>
        <h2>Witbe's virtual NOC technology for live TV monitoring</h2>
        <p>
            {'We announced today that Mitchell Seaforth Cable TV (MSCTV), a leading Canadian provider of unique solutions to telecommunications companies,'}
            {' is using Witbe’s proactive monitoring technology as a '}
            <a href='/articles/new-virtual-noc-launch-press-release/' target='_blank'>
                virtual Network Operation Center (NOC)
            </a>
            {' — accessible from anywhere in the world.'}
        </p>
        <p>
            {'With the monitoring data they receive from Witbe\'s technology, MSCTV can provide a superior video Quality of Experience to linear TV application users.'}
        </p>
        <h2>Deliver an exceptional Quality of Experience to viewers</h2>
        <p>
            {'"Video quality is a fundamental pillar of success at MSCTV. Our partnership with Witbe ensures that we can meet and exceed our customers\''}
            {' expectations for an exceptional linear viewing experience," said Michael Hawkes, General Manager at MSCTV.'}
        </p>
        <p>
            {'"Witbe\'s technology will play a significant role in ensuring that the features and content of our Pay-TV services are validated,'}
            {' tested, and presented to our customers with outstanding quality."'}
        </p>
        <figure>
            <img src='/articles/2024-10-28-msctv/msctv-_asset-articles-content-landscape.jpg' alt='Virtual NOC mosaic interface compiling your real local streams' />
            <figcaption>Witbe's virtual NOC features a mosaic interface compiling your real local streams</figcaption>
        </figure>
        <h2>Remotely control real testing devices in the field</h2>
        <p>
            {'Witbe\'s '}
            <a href='/technology/video-service-monitoring/' target='_blank'>
                proactive monitoring technology
            </a>
            {' automatically monitors any video service running on a physical device plugged into a Witbox — the company\'s signature testing robot.'}
        </p>
        <p>
            {'MSCTV is using the '}
            <a href='/products/witbox/' target='_blank'>
                Witbox+
            </a>
            {' to efficiently test TiVo white-labeled video apps running on real Set-Top Boxes.'}
        </p>
        <p>
            {'With the '}
            <a href='/technology/' target='_blank'>
                Remote Eye Controller
            </a>
            {', Witbe\'s virtual NOC, service providers can remotely control their local field-testing test devices and supervise their service from any global location.'}
        </p>
        <h2>Resolve live video errors quicker than ever before</h2>
        <p>
            "Our proactive monitoring technology gives providers a reliable eye on their video service at all times," said Mathieu Planche, CEO at Witbe.
        </p>
        <p>
            "We're pleased that our live TV monitoring technology is assisting MSCTV as they oversee many different linear TV applications, giving their team the tools to identify and resolve any potential video errors quicker than ever before."
        </p>
        <h2>Witbe's Channel Change technology for linear TV monitoring</h2>
        <p>
            {'MSCTV is also using Witbe\'s widely adopted Channel Change technology to automatically monitor the live availability of all linear channels on a video service. '}
            {'Providers utilizing this technology receive immediate alerts whenever channels are unavailable or experiencing an issue — helping teams resolve errors before viewers even notice them. '}
            {'Alerts and KPIs are also displayed live in the virtual NOC for active users to instantly verify. '}
            {'All monitoring data is then compiled in '}
            <a href='/technology/' target='_blank'>
                Smartgate
            </a>
            {', Witbe\'s observability platform, where results can be analyzed across all devices, regions, and software versions.'}
        </p>
        <h2>Contact Witbe for more information</h2>
        <p>
            {'To learn more about Witbe\'s live TV monitoring technology and Witbox product line, '}
            <a href='/contact-us/' target='_blank' alt='Contact us'>
                contact us today
            </a>
            .
        </p>
        <h3>About Mitchell Seaforth Cable TV</h3>
        <p>
            {'MSCTV is a leader in providing unique solutions to telecommunications companies, from ABR encoding to TiVo white-labeled applications for linear TV, as well as IP transport and network redundancy.'}
            {' Mitchell Seaforth Cable TV has been in the video business for over 50 years, from the analog days of providing TV through digital IPTV to current live streaming linear TV applications.'}
            {' They offer a robust network that receives videos from several locations to minimize streaming issues. MSCTV\'s headend consists of multiple points of redundancy, including two back-up generators, inverters, N+1 encoding, and their signature 100g MPLS network.'}
        </p>
    </React.Fragment>
);
export default Content;
