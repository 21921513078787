import React from 'react';

const Content = () => (
    <React.Fragment>
        <p>
            {'Witbe\'s Video Mobile Automation technology was awarded a '}
            <a href='https://www.futureevents.uk/ibcbos24/6202054' alt='Best of Show Award at IBC2024' target='_blank' rel='noreferrer'>Best of Show Award at IBC2024</a>
            {'. The Best of Show Awards recognized "the very best broadcast products and solutions on display" at '}
            <a href='/events/ibc-2024/' alt='IBC2024' target='_blank'>IBC2024</a>
            . They were selected "by a panel of industry experts on the criteria of innovation, feature set, cost efficiency, and performance in serving the industry".
        </p>
        <h2>Witbe Video Mobile Automation: Award-Winning Technology for Testing Mobile Video Apps</h2>
        <p>
            {'Witbe\'s Video Mobile Automation, which debuted at the show, was '}
            <a href='https://www.tvtechnology.com/news/tv-tech-announces-best-of-show-winners-at-2024-ibc-show' alt='selected by TV Tech' target='_blank' rel='noreferrer'>selected by TV Tech</a>
            . The new Video Mobile Automation technology allows video service providers to directly test and monitor their streaming apps running on mobile devices from anywhere in the world.
        </p>
        <p>
            {'The innovative plug-and-play technology empowers companies to accurately measure and improve their Quality of Experience on the same devices their viewers use.'}
            {' It paved the way for Witbe\'s new Mobile Quality of Experience and Video Traffic Optimization technology,'}
            {' designed to help providers and mobile network operators strike an ideal balance between streaming video quality and bandwidth usage.'}
        </p>
        <figure>
            <img src='/articles/2024-10-02-bs-award/tv-asset-articles-content-landscape.jpg' alt='TV Technology article' />
        </figure>
        <h2>Non-Reference-Based Video Quality Assessment for Streaming Services</h2>
        <p>
            {'As Witbe noted in their entry, "Video Mobile Automation is the only product on the market providing non-reference-based video quality assessment while capturing traffic usage,'}
            {' measured on any stream running on unmodified real devices, including DRM-protected content.'}
            {' It can analyze video quality like a human tester would, evaluating picture and audio by the same standards used every day. With the results from these automated tests, video service '}
            <a href='/technology/' alt='providers can adjust and improve the Quality of Experience' target='_blank'>providers can adjust and improve the Quality of Experience</a>
            {' their viewers receive."'}
        </p>
        <h2>IBC 2024: Showcasing the Latest Innovations in Broadcast and Streaming Technology</h2>
        <p>
            {'"IBC has been busier than ever this year, with over 1,300 companies showcasing their latest innovations. '}
            {'Our awards followed suit with this popularity and were more entered than ever before," commented the Best of Show Awards editorial team. '}
            {'"To all the companies who won, you can be truly proud of your achievements in standing out amongst the crowd at IBC 2024."'}
        </p>
        <h2>Optimize Streaming Quality Today with Witbe Video Mobile Automation Technology</h2>
        <p>
            {'Witbe\'s Video Mobile Automation technology and Mobile Quality of Experience and Video Traffic Optimization technology are available now. '}
            {'They are essential in empowering video service providers to maintain a strong mobile app performance, avoid network throttling, and deliver a superior viewing Quality of Experience. '}
            {'We were honored to receive this award for our latest innovations.'}
        </p>
        <p>
            {'To learn more about how Witbe\'s technology can help your team, '}
            <a href='/contact-us/' target='_blank' alt='contact us today'>contact us today</a>
            !
        </p>
    </React.Fragment>
);

export default Content;
