import React from 'react';

import Link from '../common/Link';

import styles from '../../templates/styles/blogpost.module.scss';


const Content = () => (
    <React.Fragment>
        <p>In today's fast-paced tech industry, quality assurance teams face new challenges: faster release cycles, a wide variety of viewing conditions, and the increasing complexity of devices and platforms that are all expected to run perfectly.</p>
        <p>How can QA teams speed up video testing workflows to avoid becoming the bottleneck in the product development process? Innovative new technology, like <Link to="/articles/what-is-a-virtual-noc/">Witbe&rsquo;s virtual NOC</Link>, shows a better path forward.</p>

        <h2>QA testing challenges</h2>
        <p>How can QA Engineers keep up with aggressive product cycles, A-B testing, field deployments, and localized restrictions? It has never been harder for QA teams <strong>to catch regressions before they're pushed to production.</strong></p>
        <p>If they want to deliver new releases faster with a shorter time-to-market, QA engineers must adapt their workflows and explore innovative ways to test video services beyond traditional lab testing.</p>

        <h2>QA testing across OTT devices</h2>
        <p>Video service providers must ensure their video services perform seamlessly by testing across a diverse device farm&mdash;a fleet of real devices distributed across different locations and networks, including some of the most widely used platforms:</p>
        <ul>
            <li><strong>Smart TVs:</strong> Samsung Tizen OS, LG webOS, Android TV (Sony, TCL, Hisense), Roku TV, Fire TV OS</li>
            <li><strong>Set-Top Boxes:</strong> Apple TV 4K (tvOS), Roku Ultra, Nvidia Shield TV (Android TV), Fire TV Cube</li>
            <li><strong>Mobile Phones &amp; Tablets:</strong> iPhone 15 (iOS), Samsung Galaxy S23 (Android), iPad Pro M2 (iPadOS), Google Pixel 8 (Android)</li>
            <li><strong>Gaming Consoles:</strong> PlayStation 5, Xbox Series X, Nintendo Switch</li>
            <li><strong>Streaming Devices:</strong> Chromecast with Google TV, Amazon Fire Stick 4K, Roku Streaming Stick+</li>
        </ul>
        <p>It&rsquo;s simply not realistic to have every device physically available for every engineer. Especially in fully remote setups, it's impractical to ship multiple Smart TVs or devices to every team member.</p>
        <p>With Witbe&rsquo;s Virtual NOC, teams can remotely access and test any device, anywhere, ensuring thorough QA while maintaining fast releases cycles.</p>

        <h2>Introducing Witbe&rsquo;s virtual NOC: QA testing remotely in the field</h2>
        <p>Witbe's new technology allows QA teams to test locally in the field on any Smart TV, STB, mobile phones, and streaming devices in actual customer conditions. <Link to="/articles/benefits-of-virtual-noc/">Witbe&rsquo;s virtual NOC, the Remote Eye Controller (REC)</Link>, empowers QA teams to accelerate video time-to-market by testing across the lab and field remotely.</p>
        <p>With the REC, service providers can test and monitor their video services on real devices and networks anywhere in the world. By measuring the <Link to="/articles/what-is-a-virtual-noc/">true Quality of Experience that viewers receive</Link> on real devices, the REC significantly expands QA testing workflows with field testing capabilities.</p>

        <figure>
            <img src='/articles/2024-12-09-noc-benefits/PM_asset-articles-content-landscape1.jpg' alt='Witbe Remote Eye Controller (REC)' className={styles.smallImage} />
        </figure>

        <h2>What are the key benefits of a virtual NOC for QA teams?</h2>
        <p>With Witbe's virtual NOC, <strong>QA teams can</strong>:</p>
        <ul>
            <li><strong>Monitor thousands of lab tests</strong> that run continuously in real time, and get live alerts for regressions</li>
            <li>Run lab tests manually on field devices to <strong>quickly confirm a regression or troubleshoot a problem</strong> that's only happening in real customer conditions</li>
            <li><strong>Take control of any test device</strong> to manually verify quickly a feature or bug fix</li>
            <li><strong>Record video evidence</strong> of manual and automated tests to share an error with engineering teams</li>
            <li><strong>Share device access</strong> with engineering teams to empower them to verify a fix to production by themselves</li>
        </ul>

        <h2>How can teams resolve issues faster with a virtual NOC?</h2>
        <p>When an issue strikes, it can be technically complicated and time-consuming for QA teams to resolve it. An engineer has to come onboard the resolution project, see and acknowledge the issue, find a way to replicate it, and test until they find a solution. With Witbe&rsquo;s virtual NOC<strong>, team members can simply share a URL link to the malfunctioning device</strong>. With the link, an engineer can directly access the device, see a recording of the issue, and replicate and test it with ease.</p>
        <p>This expedited resolution process is the same for engineers outside of your company. Virtual NOC links can be shared across different entities of a company and with third-parties to help resolve issues on specific platforms. For instance, a Set-Top Box operator like Cox could give access to engineers from third-party video app providers like Hulu or Apple to resolve any issues with their app directly on Cox&rsquo;s platform. With this technology, Witbe&rsquo;s virtual NOC <strong>greatly improves communication, collaboration, and issue resolution.</strong></p>

        <h2>Unlock real device testing for QA teams today</h2>
        <p>It&rsquo;s time to join the blended QA-field-testing movement for faster, higher-quality releases!<br /><Link to="/contact-us/">Contact us</Link> today to discover how Witbe's virtual NOC can level up your QA process.</p>
    </React.Fragment>
);
export default Content;
