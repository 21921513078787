import React from 'react';

const Article = () => (
    <React.Fragment>
        <h2>Transforming Video Monitoring with the Virtual NOC</h2>
        <p>
            We revealed today a new version of Witbe's popular
            {' '}
            <a href='/technology/' target='_blank'>
                Remote Eye Controller (REC)
            </a>
            {' '}
            application. Witbe's latest
            update is packed with features that expand the software's monitoring, recording, and customization capabilities,
            boosting it into a central station for all live and on-demand video monitoring needs.
        </p>
        <p>
            The REC becomes a true virtual Network Operation Center (NOC) with this update, where providers can remotely
            control and monitor any
            {' '}
            <a href='/technology/supported-devices/' target='_blank'>
                real testing device
            </a>
            {' '}
            , from Set-Top Boxes and web applications to mobile devices and Smart
            TVs. Witbe's technology offers providers the competitive edge of an accelerated market launch, designed for the
            current operations standard of continuous post-release testing in the field following brief intensive pre-production QA
            testing.
        </p>

        <figure>
            <img src='/articles/2024-10-02-noc/noc-asset-articles-content-landscape.jpg' alt='Virtual NOC landscape' />
        </figure>

        <h2>Customization for a Tailored Monitoring Experience</h2>
        <p>
            Customization is a core tenet of the redesign. When users log on to the new REC for the first time, they now have the
            option to choose how much information they want displayed on their device's streams.
        </p>
        <p>
            This allows teams to tailor the software's mosaic view to their specific needs, customizing the size of the live feed
            screens and the level of detailed information. Users can optimize their experience whether they are accessing the REC
            from their mobile device, computer, or across multiple screens in their office NOC.
        </p>
        <p>
            Providers remotely accessing their video service will benefit from the new ability to record a device's live feed at any
            time in the REC, whether they want to capture an error that caught their eye or need to record steps so they can
            reproduce an error in the field outside of an
            {' '}
            <a href='/technology/qa-test-automation/' target='_blank'>
                automated testing session.
            </a>
        </p>
        <p>
            Recordings can then be quickly shared with the appropriate teams to escalate an issue and ensure it gets resolved,
            capturing errors that can only be seen on real devices. With this update, remote troubleshooting in the field is easier
            than ever before.
        </p>

        <h2>Instant Feedback and Key Performance Indicators</h2>
        <p>
            In what may be its most transformative feature, Witbe's Remote Eye Controller will now display key performance
            indicators and alerts in real time on the mosaic view. When errors are automatically detected, such as live channels or
            on-demand assets with no audio, ad slates, or black screens, video service providers will immediately see them in the
            REC.
        </p>
        <p>
            This easily readable instant feedback empowers video service providers to supervise their service's availability,
            performance, and video quality from
            {' '}
            <a href='/technology/video-service-monitoring/' target='_blank'>
                one central application
            </a>
            {' '}
            without needing to consult separate software for different
            monitoring needs, centering their workflow and streamlining their operations.
        </p>
        <blockquote>
            <p>
                "Witbe's Remote Eye Controller continues to evolve to meet the ever-changing, cutting-edge needs of video service
                providers," said Mathieu Planche, CEO at Witbe.
            </p>
        </blockquote>

        <blockquote>
            <p>
                "This new Remote Eye Controller is the largest software update we have ever launched. It transforms the REC from
                the most efficient device control center on the market into a comprehensive video monitoring application, combining
                video streams running on real devices in the field with alerts from automated test scenarios
                {' '}
                <a href='/products/witbox/' target='_blank'>
                    running on the Witbox
                </a>
                .
                Witbe has made a virtual NOC for every provider, accessible from any web browser at any time."
            </p>
        </blockquote>

        <h2>The Impact of the New Virtual NOC on Video Service Providers</h2>
        <p>
            This new Remote Eye Controller update also contains many other features. The mosaic view has also been updated to
            support an unlimited number of devices in an infinite scroll. Users can now display up to two hundred devices
            simultaneously on a 4K monitor in full-screen and scroll to see all their devices at once.
        </p>
        <p>
            Other features focus on further customizing the REC, including the ability to mark certain devices or clusters as
            "favorites," or filter by device status or activity. This virtual NOC update empowers video service providers to
            personalize remote workspaces, encouraging global collaboration and ensuring teams can quickly scan their devices to
            resolve streaming errors.
        </p>
        <p>
            Witbe's new and improved Remote Eye Controller was previewed at IBC2024 in Amsterdam and the SCTE TechExpo 24 in
            Atlanta, and will be deployed for all current and incoming customers later in the fall of 2024.
        </p>
        <p>
            To learn more,
            {' '}
            <a href='/contact-us/' target='_blank'>
                contact us today
            </a>
            .
        </p>
    </React.Fragment>
);

export default Article;
