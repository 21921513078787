import React from 'react';
import { useTranslation } from 'react-i18next';

import monitorEndUsers from '../../images/resources/Screenshot25.png';
import unifiedControl from '../../images/resources/Screenshot17.png';
import oneScreen from '../../images/resources/Screenshot27.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <h2>{t(`siteMetadata.resources.articles.unifiedCommunications.h2`)}</h2>
            <figure>
                <img src='/articles/old/Unified_Communications_Monitoring.jpg' id={newSnapshots.mainImg} alt='Witbe Smartgate' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.unifiedCommunications.mainText1`)}</p>


            <h3>{t(`siteMetadata.resources.articles.unifiedCommunications.sectionATitle`)}</h3>
            <figure>
                <img src={monitorEndUsers} alt='Witbe Smartgate'/>
            </figure>
            <p>{t(`siteMetadata.resources.articles.unifiedCommunications.sectionAContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.unifiedCommunications.sectionBTitle`)}</h3>
            <figure>
                <img src={unifiedControl} alt='Witbe Smartgate'/>
            </figure>
            <p>{t(`siteMetadata.resources.articles.unifiedCommunications.sectionBContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.unifiedCommunications.sectionCTitle`)}</h3>
            <figure>
                <img src={oneScreen} alt='Witbe Smartgate'/>
            </figure>
            <p>{t(`siteMetadata.resources.articles.unifiedCommunications.sectionCContent1`)}</p>
        </React.Fragment>
    );
}

export default Content;
