import React from 'react';
import { useTranslation } from 'react-i18next';

import portal from '../../images/resources/Screenshot33.png';
import alerting from '../../images/resources/Screenshot34.jpg';

import styles from '../../templates/styles/blogpost.module.scss';
import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <figure>
                <img src='/articles/2020-09-18-ivr-call-centers/witbox-rack.jpg' id={newSnapshots.mainImg} alt='Witbe Witbox' />
            </figure>

            <h2>{t(`siteMetadata.resources.articles.IVRCallCenters.h2`)}</h2>
            <p>{t(`siteMetadata.resources.articles.IVRCallCenters.mainText1`)}</p>
            <p>{t(`siteMetadata.resources.articles.IVRCallCenters.mainText2`)}</p>

            <h3>{t(`siteMetadata.resources.articles.IVRCallCenters.sectionATitle`)}</h3>
            <figure>
                <img src='/articles/2020-09-18-ivr-call-centers/witbox+.jpg' alt='Witbe Witbox+' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.IVRCallCenters.sectionAContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.IVRCallCenters.sectionBTitle`)}</h3>
            <figure>
                <img src={portal} alt='Witbe Smartgate' />
            </figure>
            <p className={styles.resourceSectionText}>{t(`siteMetadata.resources.articles.IVRCallCenters.sectionBContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.IVRCallCenters.sectionCTitle`)}</h3>
            <figure>
                <img src={alerting} alt='Witbe Smartgate' />
            </figure>
            <p className={styles.resourceSectionText}>{t(`siteMetadata.resources.articles.IVRCallCenters.sectionCContent1`)}</p>
        </React.Fragment>
    );
}

export default Content;
