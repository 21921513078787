import React from 'react';
import { useTranslation } from 'react-i18next';

import testAndValidate from '../../images/resources/Screenshot35.jpg';
import testEndUsers from '../../images/resources/Screenshot36.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t('siteMetadata.resources.articles.STB.h2')}
            </h2>
            <figure>
                <img src="/articles/old/Set_Top_Box_Test_Automation_and_Validation.jpg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <div className={newSnapshots.bodyTextWithBorder}>
                <span></span>
                <p className={newSnapshots.bodyTextWithPargin}>
                    <p>{t('siteMetadata.resources.articles.STB.mainText1')}</p>
                    <p>{t('siteMetadata.resources.articles.STB.mainText2')}</p>
                </p>
            </div>
            <figure>
                <img src={testAndValidate} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.STB.sectionATitle')}</h2>
            <p>{t('siteMetadata.resources.articles.STB.sectionAContent1')}</p>
            <p>{t('siteMetadata.resources.articles.STB.sectionAContent2')}</p>
            <figure>
                <img src={testEndUsers} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.STB.sectionBTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.STB.sectionBContent1')}}></p>
        </React.Fragment>
    );
}

export default Content;
