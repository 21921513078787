import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';

import banner from '../../images/resources/banners/superbowlbanner.jpg';
import superbowl1 from '../../images/resources/superbowl1.png';
import superbowl2 from '../../images/resources/superbowl2.png';
import superbowl3 from '../../images/resources/superbowl3.png';
import superbowl4 from '../../images/resources/superbowl4.png';
import superbowl5 from '../../images/resources/superbowl5.png';
import superbowl6 from '../../images/resources/superbowl6.jpeg';
import superbowl7 from '../../images/resources/superbowl7.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.snapshots.superbowl.introTitle')}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.ul1.ulItem1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul1.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul1.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul1.ulItem4')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.ul2.ulItem1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul2.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul2.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul2.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul2.ulItem5')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.ul3.ulItem1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul3.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul3.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul3.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul3.ulItem5')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.ul4.ulItem1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul4.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul4.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul4.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul4.ulItem5')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem5')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem6')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul5.ulItem7')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem5')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem6')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul6.ulItem7')}</li>
            </ul>
            <figure>
                <img src={superbowl1} alt='Screenshot' />
            </figure>
            <figure>
                <img src={superbowl2} alt='Screenshot' />
            </figure>
            <figure>
                <img src={superbowl3} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent4')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent5')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.superbowl.ul7.ulItem1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.superbowl.ul7.ulItem2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.superbowl.ul7.ulItem3')}}></li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent6')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.introContent7')}</p>
            <h2>{t('siteMetadata.resources.snapshots.superbowl.overviewTitle')}</h2>
            <figure>
                <img src={superbowl4} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.superbowl.overviewContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.overviewContent2')}</p>
            <figure>
                <img src={superbowl5} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.superbowl.overviewContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.overviewContent4')}</p>
            <div className={newSnapshots.snapshotSectionFiguresWrapper}>
                <figure>
                    <img src={superbowl6} />
                    <figcaption>{t('siteMetadata.resources.snapshots.superbowl.overviewSubtitle1')}</figcaption>
                </figure>
                <figure>
                    <img src={superbowl7} />
                    <figcaption>{t('siteMetadata.resources.snapshots.superbowl.overviewSubtitle2')}</figcaption>
                </figure>
            </div>
            <h2>{t('siteMetadata.resources.snapshots.superbowl.halftimeTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.superbowl.halftimeContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.halftimeContent2')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul8.ulItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul8.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul8.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul8.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.superbowl.ul8.ulItem5')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.superbowl.halftimeContent3')}</p>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src='https://www.witbe.net/wp-content/uploads/2019/02/Audio-issues.mp4' type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.stadia.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.superbowl.halftimeContent4')}</p>
            <h2>{t('siteMetadata.resources.snapshots.superbowl.adInsertionTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.superbowl.adInsertionContent1')}</p>
            <div className={newSnapshots.quotedText}>
                <span></span>
                <p className={newSnapshots.quotedTextWithPargin}>
                    <FontAwesomeIcon icon={faQuoteLeft} className={newSnapshots.quoteIconLeft} />
                    {t('siteMetadata.resources.snapshots.superbowl.adInsertionQuote')}
                    <FontAwesomeIcon icon={faQuoteRight} className={newSnapshots.quoteIconRight} />
                </p>
            </div>
            <p>{t('siteMetadata.resources.snapshots.superbowl.adInsertionContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.adInsertionContent3')}</p>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src='https://www.witbe.net/wp-content/uploads/2019/02/Ad-insertion-issues-super-bowl.mp4' type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.stadia.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.superbowl.adInsertionContent4')}</p>
            <h2>{t('siteMetadata.resources.snapshots.superbowl.conclusionTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.superbowl.conclusionContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.conclusionContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.conclusionContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.conclusionContent4')}</p>
            <h2>{t('siteMetadata.resources.snapshots.superbowl.QOESnapshotsTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.superbowl.QOESnapshotsContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.QOESnapshotsContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.superbowl.QOESnapshotsContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
