import React, { useState, useEffect } from 'react';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import BlogSidebarPosts from '../components/blog/BlogSidebarPosts';
import SubscribeBlueBoxModal from '../components/common/SubscribeBlueBoxModal';
import BlogSidebarTags from '../components/blog/BlogSidebarTags';
import BlogFooterPosts from '../components/blog/BlogFooterPosts';
import styles from './styles/blogpost.module.scss';
import { useViewport } from '../hooks';
import { useLocation } from '@reach/router';

/**
* Single article view (/:slug)
*
* This file renders a single article and loads all the content.
*
*/
const Article = ({ pageContext }) => {
    const { width } = useViewport();
    const breakpoint = 500;

    const { pathname } = useLocation();

    const post = pageContext;
    const {
        id,
        bodyTitle,
        metaTitle,
        metaDescription,
        metaImage,
        date,
        tags,
        mainTopic,
        topics,
        industries,
        description,
        body,
        bodyComponent
    } = post;

    const [ BodyComponent, setBodyComponent ] = useState(null);

    // @FIXME(ffa): Gatsby v2 doesn't support dynamic imports yet, so we need to use this workaround
    // We could use the following code instead, but it doesn't work with Gatsby v2
    // const BodyComponent = bodyComponent ? lazy(() => import(`../components/articles/${bodyComponent}.js`)) : null;
    useEffect(() => {
        if (typeof window !== 'undefined' && bodyComponent) {
            // eslint-disable-next-line import/no-dynamic-require, security/detect-non-literal-require, unicorn/prefer-module
            const LoadedComponent = require(`../components/articles/${bodyComponent}.js`).default;
            setBodyComponent(() => LoadedComponent);
        }
    }, [ bodyComponent ]);

    return (
        <Layout title={metaTitle} isEvents>
            <SEO
                title={metaTitle}
                description={metaDescription}
                image={metaImage}
                article='article'
                ogDescription={metaDescription}
            />
            <div id={styles.mainImageWrapper}>
                <div className={styles.mainImageTextWrapper}>
                    <div className={styles.mainImageLeft}>
                        <div className={styles.mainImageContent}>
                            <h1 className={styles.mainImageTitle} dangerouslySetInnerHTML={{ __html: bodyTitle }} />
                        </div>
                    </div>
                </div>
            </div>
            <section id={styles.articlesContainer}>
                <div className={styles.articlesMain}>
                    <section className={styles.articlesContainer}>
                        {BodyComponent
                            ? (
                                <div className={styles.bodyText}>
                                    <BodyComponent />
                                </div>
                            )
                            : <div className={styles.bodyText} dangerouslySetInnerHTML={{ __html: body }} />}
                        {width < breakpoint
                            ? <BlogSidebarTags id={id} tags={tags} topics={topics} industries={industries} date={date} />
                            : null}
                        {width < breakpoint
                            ? <BlogSidebarPosts id={id} category={mainTopic} />
                            : null}
                    </section>
                </div>
                <aside className={styles.sidebar}>
                    <div className={styles.listsContainer}>
                        <BlogSidebarTags id={id} tags={tags} topics={topics} industries={industries} date={date} />
                        <SubscribeBlueBoxModal />
                        <BlogSidebarPosts id={id} category={mainTopic} />
                    </div>
                </aside>
            </section>
            {width < breakpoint
                ? <SubscribeBlueBoxModal />
                : null}
            {width > breakpoint
                ? <BlogFooterPosts id={id} />
                : null}
        </Layout>
    );
};

export default Article;
