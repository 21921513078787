import React from 'react';

import Link from '../common/Link';

import styles from '../../templates/styles/blogpost.module.scss';


const Content = () => (
    <React.Fragment>
        <p>Witbe’s <Link to='/articles/new-virtual-noc-launch-press-release/'>virtual NOC technology</Link> was recognized in the Best Streaming Technology category at <a href='https://digitalmediaworld.tv/awards/dmw-awards-2024-winners' rel='external' target='_blank'>the Digital Media World Awards 2024</a>.</p>
        <p>The Digital Media World Awards recognize “the work that organizations and teams have devoted to content creation, distribution, and consumers everywhere,” awarding the best new technology that reflects “the important advances the broadcast and media industries have made over the last twelve months.”</p>
        <p>Most video service providers rely on a Network Operations Center (NOC) where Video Operations teams monitor the source stream of their broadcasts to get a comprehensive view of their video service's performance.</p>
        <p>However, the video quality that is sent out is not always the same quality received by viewers. Witbe’s <Link to='/technology/remote-device-access/'>Remote Eye Controller (REC)</Link>, our virtual NOC, addresses this challenge by compiling live streams from <Link to='/technology/supported-devices/'>real local testing devices</Link>, including Smart TVs, Set-Top Boxes, mobile devices, and tablets, in a web app.</p>
        <p>With the REC, providers can test and monitor their service’s performance on real devices and networks, at the furthest point in the content delivery chain, from anywhere in the world.</p>

        <figure>
            <img src='/articles/2025-02-04-dmw/noc_asset-articles.jpg' alt='Witbe’s virtual NOC – the Remote Eye Controller' className={styles.smallImage} />
        </figure>

        <p>As we noted in <a href='https://www.digitalmediaworld.tv/awards/witbe-virtual-noc-technology' rel='external' target='_blank'>our entry</a>,
        “In order to accurately measure the Quality of Experience that viewers actually receive, video service providers need to test and monitor how their streaming video apps run on real devices and networks in the markets where their service operates. The REC removes the limitations of traditional device testing, empowering providers to access and control their testing devices from anywhere in the world. This eliminates the time and expense required for engineers to travel thousands of miles to test specific malfunctioning devices in the field and empowers multiple teams to work on the same data. With the REC, providers can test their video service running on a device halfway across the world from the comfort of their own smartphone’s web browser.”</p>

        <p>The Remote Eye Controller previously won Best Cloud Technology at the Digital Media World Awards in 2022, while the <Link to='/articles/smart-navigate-ai-test-automation-for-video-service-providers/'>Smart Navigate AI algorithm</Link> was recognized in the Best QA Monitoring category in 2023.</p>

        <p>Witbe’s virtual NOC technology is available now.</p>
        <p>For more information, check out these links:</p>
        <ul>
            <li><Link to='/articles/new-virtual-noc-launch-press-release/'>Official announcement</Link></li>
            <li><Link to='/articles/msctv-partnership-virtual-noc-live-monitoring/'>Customer testimonial</Link></li>
            <li><Link to='/articles/what-is-a-virtual-noc/'>Deep dive into its use</Link></li>
            <li><Link to='/articles/benefits-of-virtual-noc/'>And its benefits</Link></li>
        </ul>
        <p><Link to='/contact-us/'>Contact us today</Link> to learn how our technology can help your team!</p>
    </React.Fragment>
);
export default Content;
