import React from 'react';

import Link from '../common/Link';

const Content = () => (
    <React.Fragment>
        <p><b>NEW YORK — Jan. 15, 2025 — Witbe (Euronext Growth – FR0013143872 – ALWIT),</b> a global leader in test automation and monitoring technology for video service providers, today announced that it has joined the Digital TV Group (DTG), the UK-based industry association focused on the innovation, development, and promotion of digital television and media technologies.</p>
        <p>DTG has driven digital TV innovation in the UK for over 25 years, helping its members efficiently deliver video services across all screens, devices, and formats. DTG’s mission is aligned with Witbe’s dedicated approach to testing and monitoring video on real devices, measuring the true quality that is delivered to viewers at home and on the go.</p>
        <p>“We are delighted to welcome Witbe as a new member of the Digital TV Group,” said DTG CEO Richard Lindsay-Davies.</p>
        <blockquote>“Their expertise in test automation and monitoring aligns perfectly with our mission to support innovation and excellence in UK TV delivery. At a time when the industry is navigating significant transformation, we look forward to collaborating with Witbe to ensure exceptional viewer experiences and drive value for our organizations and the wider ecosystem, as we work to shape the future of TV in the UK.”</blockquote>
        <p>As a DTG member, Witbe will share its substantial industry expertise as it looks to strengthen its presence in the UK market. The company’s technology is used by major video service providers worldwide, including DTG member Channel Four Television Corporation.</p>
        <p>Witbe recently updated its <b>innovative Remote Eye Controller (REC) application, a virtual Network Operations Center (NOC),</b> to make it even easier for video service providers to remotely access and control real testing devices from any web browser. With the REC, teams can verify and resolve streaming errors faster than ever before, from anywhere in the world.</p>
        <p>“We are pleased to be joining the Digital TV Group,” commented Mathieu Planche, Witbe’s CEO.</p>
        <blockquote>“DTG’s commitment to collaboration and testing in the UK television industry is perfectly aligned with the core principles of Witbe’s technology. As a new DTG member, we are excited to participate in talks about the future of UK streaming, collaborate with fellow members, and share our vast expertise shaped by over 20 years of working with providers to test and monitor their services on real devices and networks.”</blockquote>
        <p>Additional information about Witbe’s automated testing and monitoring technology is available at <Link to='/' >witbe.net</Link>.</p>

        <h2>About Witbe</h2>
        <p>Witbe (Euronext Growth - FR0013143872 - ALWIT) makes automated testing and proactive monitoring technology for global video service providers. The company’s award-winning technology accurately measures the Quality of Experience that viewers receive.</p>
        <p>Witbe’s non-intrusive, plug-and-play technology replicates user behavior to automatically test and monitor video services from anywhere in the world. The Witbe Software Suite allows teams to remotely access their Witbox robot, which can control, test, and monitor any video app running on a real device. The essential data collected by the Witbox can then be analyzed and shared in Witbe’s observability platform.</p>
        <p>Witbe’s recent innovations include its streamlined Automated Testing Set, deploying automation and providing QA results faster than ever before; its popular Ad Monitoring and Matching technology to resolve Dynamic Ad Insertion errors; its acclaimed Smart Navigate AI algorithm; and its new Mobile Quality of Experience and Video Traffic Optimization technology.</p>
        <p>Witbe has a dozen offices around the world and is proud to have customers — including Comcast, Cox, Verizon, Peacock, and Orange — in more than 50 countries. More information and further updates are available at <Link to='/' >witbe.net</Link>.</p>

        <h2>About Digital TV Group</h2>
        <p>For more than 25 years, the Digital TV Group (DTG) has been central to driving digital TV innovation in the UK - from leading the digital TV switchover to making interactive TV happen, rolling out on-demand TV, and introducing HDTV and UHD TV.</p>
        <p>DTG works with the best of industry on the future of television, embracing the convergence of content and networks to efficiently deliver video to all screens, across all devices, in all formats. The Zoo at DTG Testing is the UK’s only comprehensive testing and accreditation center for digital TV devices and services. For more information, visit <a href="https://dtg.org.uk/" rel="external" target='_blank'>dtg.org.uk</a>.</p>
     </React.Fragment>
);
export default Content;
