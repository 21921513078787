import React from 'react';
import { useTranslation } from 'react-i18next';

import banner from '../../../static/articles/old/Snapshot6Picture1.jpg';
import REC from '../../images/resources/Snapshot6Picture2.png';
import channelChangeTime from '../../images/resources/Snapshot6Picture3.jpg';
import SnapshotVideo1 from '../../images/resources/Orange CC OK w logo.mp4';
import SnapshotVideo2 from '../../images/resources/Bell CC OK w logo.mp4';
import SnapshotVideo3 from '../../images/resources/Cox CC OK w logo.mp4';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';


const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introTitle')}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent2')}</p>
            <p><b>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulTitle')}</b></p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem5')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem6')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem7')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem8')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem9')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem10')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul1.ulItem11')}</li>
            </ul>
            <p><b>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulTitle')}</b></p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem4')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem5')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem6')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem7')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem8')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem9')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem10')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul2.ulItem11')}</li>
            </ul>
            <h2>Witbe Remote Eye Controller</h2>
            <figure>
                <img src={REC} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent4')}</p>
            <ol>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ol1.olItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ol1.olItem2')}</li>
            </ol>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent5')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent6')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul3.ulItem1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul3.ulItem2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul3.ulItem3')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul3.ulItem4')}}></li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent7')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.introContent8')}</p>
            <h2>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.overviewTitle')}</h2>
            <figure>
                <img src={channelChangeTime} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.overviewContent1')}} />
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.overviewContent2')}} />
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ulItem1')}</p>
            <ol>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ol.olItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ol.olItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ol.olItem3')}</li>
            </ol>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ulItem2')}</p>
            <ol>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ol2.olItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ol2.olItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul4.ol2.olItem3')}</li>
            </ol>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.overviewContent3')}} />
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.overviewContent4')}} />
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.overviewContent5')}} />
            <h2>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeTitle')}</h2>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={SnapshotVideo1} type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeContent1')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul6.ulItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul6.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ul6.ulItem3')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeContent4')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeContent5')}</p>
            <ol>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ol2.olItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ol2.olItem2')}</li>
            </ol>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.channelChangeContent6')}</p>
            <h2>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.bestPracticesTitle')}</h2>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={SnapshotVideo2} type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.bestPracticesContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.bestPracticesContent2')}</p>
            <ol>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ol3.olItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.ol3.olItem2')}</li>
            </ol>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.bestPracticesContent3')}</p>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={SnapshotVideo3} type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.videoErrorMessage')}
                </video>
            </div>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.bestPracticesContent4')}} />
            <h2>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.conclusionTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.conclusionContent1')}} />
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.conclusionContent2')}} />
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.conclusionContent4')}} />
            <h2>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.QOESnapshotsTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.QOESnapshotsContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.QOESnapshotsContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeNorthAmericaAndEurope.QOESnapshotsContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
