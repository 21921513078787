import React from 'react';
import { useTranslation } from 'react-i18next';

import fiveG1 from '../../images/resources/5g1.png';
import fiveG2 from '../../images/resources/5g2.png';
import fiveG3 from '../../images/resources/5g3.png';
import fiveG4 from '../../images/resources/5g4.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.snapshots.5G.introTitle')}</h2>
            <figure>
                <img src="/articles/old/5g_Banner3.jpeg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.5G.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.5G.introContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.5G.introContent3')}</p>
            <h2>{t('siteMetadata.resources.snapshots.5G.videoQualityTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent2')}</p>
            <figure>
                <img src={fiveG1} alt='Screenshot' />
                <figcaption>{t('siteMetadata.resources.snapshots.5G.videoQualitySubtitle')}</figcaption>
            </figure>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent3')}</p>
            <p><b>{t('siteMetadata.resources.snapshots.5G.videoQualityContent4')}</b></p>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent5')}</p>
            <figure>
                <img src={fiveG2} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent6')}</p>
            <figure>
                <img src={fiveG3} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent7')}</p>
            <p><b>{t('siteMetadata.resources.snapshots.5G.videoQualityContent8')}</b></p>
            <p>{t('siteMetadata.resources.snapshots.5G.videoQualityContent9')}</p>
            <h2>{t('siteMetadata.resources.snapshots.5G.methodologyTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.5G.methodologyContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.5G.methodologyContent2')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.ul1.ulItem1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.ul1.ulItem2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.ul1.ulItem3')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.ul1.ulItem4')}}></li>
            </ul>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.methodologyContent3')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.methodologyContent4')}}></p>
            <h2>{t('siteMetadata.resources.snapshots.5G.whatNextTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.5G.whatNextContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.5G.whatNextContent2')}</p>
            <figure>
                <img src={fiveG4} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.5G.whatNextContent3')}}></p>
            <p>{t('siteMetadata.resources.snapshots.5G.whatNextContent4')}</p>
            <p>{t('siteMetadata.resources.snapshots.5G.whatNextContent5')}</p>
        </React.Fragment>
    );
}

export default Content;
