import React from 'react';

const Content = () => (
    <React.Fragment>
        <h2>Recognizing Excellence in Ad Monitoring Technology</h2>
        <p>
            {'Witbe\'s '}
            <a href='/articles/witbe-ad-monitoring-content-matching-best-video-advertising-solution-2023/' target='_blank' alt='Ad Monitoring and Matching technology'>
                Ad Monitoring and Matching technology
            </a>
            {' was honored by the 2024 Diamond Technology Reviews in a ceremony at the SCTE TechExpo 24.'}
            {' The technology was awarded 4.5 Diamonds out of 5 in the "Monitoring and Analysis" category.'}
            {' The Diamond Technology Reviews, presented by Lightwave+BTR, "recognize technology developers demonstrating excellence in serving the broadband community."'}
            {' The Diamond Technology Reviews have previously awarded '}
            <a href='/articles/witbes-rec-monitoring-tool-and-analysis-tool-wins-4-diamonds/' target='_blank' alt='Remote Eye Controller'>
                Witbe's Remote Eye Controller
            </a>
            {' and '}
            <a href='/articles/btr-recommends-for-testing-and-monitoring-device/' target='_blank' alt='Witbox+'>
                Witbox+
            </a>
            .
        </p>
        <h2>Addressing Dynamic Ad Insertion Challenges</h2>
        <p>
            {'Witbe\'s Ad Monitoring and Matching technology was developed to help video service providers identify and resolve streaming errors caused by Dynamic Ad Insertion.'}
            {' The technology monitors ad-supported video streams '}
            <a href='/technology/' target='_blank' alt='Witbe technology'>
                on real devices and networks
            </a>
            , allowing it to measure the true ad performance that viewers receive.
        </p>
        <p>
            Ad Monitoring and Matching records Key Performance Indicators while monitoring streams, including buffering, audio issues, black screens, and full crashes, and lets providers easily share video recordings of any errors.
        </p>
        <figure>
            <img src='/articles/2024-10-02-dtr/dtr-asset-articles-content-landscape.jpg' alt='2024 Diamond Technology Reviews' />
        </figure>
        <h2>The Impact of Dynamic Ad Insertion on Revenue</h2>
        <p>
            {'As Witbe noted in their entry, "In the company\'s independent research, Witbe has observed that up to 30% of online viewing sessions are affected by Dynamic Ad Insertion issues.'}
            {' This is even more significant when you consider that ad revenue makes up a large portion of a service provider\'s revenue.'}
        </p>
        <p>
            {'When advertisers see ads are not running as intended, they may withhold payment or demand more ads be run for free.'}
            {' Some ad pods might even get filled with blank slates instead of ads — leaving money on the table for video service providers. Delivering ads correctly is a top priority to providers."'}
        </p>
        <h2>Enhancing Quality of Experience for Viewers and Advertisers</h2>
        <p>
            {'In their announcement, '}
            <a href='https://www.lightwaveonline.com/home/article/55140803/witbe-ad-monitoring-and-matching-technology' target='_blank' alt='The Diamond Technology Reviews judges noted article' rel='noreferrer'>
                the Diamond Technology Reviews judges noted
            </a>
            {' that "by offering features like video recordings for ad verification and identifying ad delivery instances, Witbe enables service providers to efficiently resolve ad errors, reduce operational expenses, and maintain ad revenue, thereby improving the overall Quality of Experience for viewers and advertisers."'}
        </p>
        <h2>Industry Recognition and Awards</h2>
        <p>
            {'Sean Buckley, Lightwave+BTR\'s Editor in Chief, also commented "I would like to congratulate Witbe on their 4.5 Diamonds honoree status.'}
            {' This program recognizes and rewards the top products and solutions available to the broadband cable industry.'}
            {' We appreciate all entries we receive, though not all were honored with 3.5 Diamonds or above!"'}
        </p>
        <p>
            {'Witbe\'s Ad Monitoring and Matching technology is available now for all providers working with Dynamic Ad Insertion, including FAST channels and AVOD services.'}
            {'The technology was just named the '}
            <a href='/articles/csi-award-ad-monitoring-2024' target='_blank'>
                "Best Advance in Ad Tech"
            </a>
            {' at he CSI Awards 2024, and has previously been awarded by the '}
            <a href='/articles/ad-monitoring-nab-product-of-the-year-award-2024/' target='_blank'>
                2024 Product of the Year Awards
            </a>
            {' and called the "Best Video Advertising Solution" at the Streaming Media European Innovation Awards.'}
        </p>
        <p>
            {'To learn more about how Witbe\'s technology can boost your ad-supported video service, '}
            <a href='/contact-us/' target='_blank' alt='Contact us'>
                contact us today
            </a>
            .
        </p>
    </React.Fragment>
);

export default Content;
