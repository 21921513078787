import React from 'react';

import Link from '../common/Link';

const Content = () => (
    <React.Fragment>
        <h2>Companies Unveiled Advanced Latency Testing and Stream Performance Analysis During the American Football Championship Game on Sunday. </h2>

        <p><b>NEW YORK — </b><b>Feb. 11, 2025</b> —TAG Video Systems, the leader in software-based realtime media monitoring and visualization, and Witbe (Euronext Growth – FR0013143872 – ALWIT), the leader in post-device automated testing and monitoring for video service providers, today announced a strategic partnership to elevate video monitoring and quality assurance to cover the entire OTT content delivery chain.</p>

        <p>This partnership combines TAG’s advanced monitoring, including its cutting-edge Content Matching technology, and Witbe’s unique Virtual NOC with automated video monitoring on real devices. It brings an unprecedented end-to-end monitoring solution that covers the entire video delivery path – from the source feed to the actual viewer experience across multiple devices and locations.</p>

        <blockquote>"This partnership marks another milestone in TAG’s mission to deliver multi-app monitoring at scale through our Realtime Media Platform," commented Ziv Mor, chief growth officer at TAG. "With Witbe's unique user-device perspective, we're closing the loop on end-to-end visibility. Our customers now have critical insight all the way to the viewer's device, allowing them to track virtually every quality aspect, including latency, across the entire network, from source to screen, and proactively address the ongoing demand for a better and seamless viewer experience."</blockquote>

        <p>As part of their collaboration, TAG and Witbe joined forces in a case study during this high-profile live event to demonstrate the strengths and value proposition of their partnership. The case study showcases their combined technologies in action, assessing both the network and the content delivered at the end user level – the actual viewer experience. This includes monitoring app performance, initial buffering time, and content matching to verify the stream’s integrity, while also for the first time this study also measures latency between the various streaming platforms – which would not be possible without visibility into the end user stream. Together, the companies conducted comprehensive monitoring of Sunday’s football championship streaming across a number of traditional cable providers and OTT streaming platforms – providing exclusive insights into how major streaming services handled the challenge of delivering a seamless viewing experience during a high-profile live event.</p>

        <blockquote>“At Witbe, we are constantly pushing the boundaries of monitoring and automated testing, so our customers can accurately measure the true quality of experience they deliver to their viewers,” said Mathieu Planche, CEO at Witbe. “Partnering with TAG is allowing us to solve two critical challenges for our customers: accurately measuring the end-to-end latency, and ensuring the correct content reaches end-user devices. With our combined expertise, we can now not only provide content providers and broadcasters with the ability to measure the true quality of their services on real test devices but also gain insights into how their streams perform across the network.”</blockquote>

        <h2>A Game-Changer for Video Monitoring</h2>
        <p>The rapid growth of streaming has made it more critical than ever for content providers to deliver consistent, high-quality video experiences across devices and networks. By integrating TAG’s network real-time data analysis with Witbe’s real-time video quality analysis and active monitoring on real devices, the partnership enables service providers to:</p>
        <ul>
            <li><b>Monitor and visualize</b> their entire content delivery workflow from source to the actual viewers devices.</li>
            <li><b>Measure and compare latency</b> across different platforms, from the source all the way to the end user stream, examining video delay between traditional broadcast and OTT streaming devices.</li>
            <li><b>Assess crucial KPIs </b>such as initial buffering time, video quality, macro blocking, and app performance on real devices with Witbe’s technology, while also benefiting from TAG’s extensive suite of additional measurements and inspections, providing continuous, in-depth monitoring and the ability to have visibility and verify viewer potential issues.</li>
            <li>Ensure the right content is being streamed across the delivery path, <b>all the way to end-user devices</b>, or verifying content switching to detect any expected or unexpected changes during delivery.</li>
        </ul>

        <h2>Exclusive Championship Game Insights</h2>
        <p>During the Big Game 2025, Witbe and TAG deployed their joint monitoring solution across multiple locations and devices, collecting real-time data on:</p>
        <ul>
            <li>Latency differences between platforms (OTT vs. traditional broadcast).</li>
            <li>Streaming performance under peak traffic conditions.</li>
            <li>Visual and audio quality metrics across different networks and devices.</li>
            <li>Real-time error detection and troubleshooting insights.</li>
            <li>Content matching.</li>
        </ul>

        <p>The results of this monitoring session are compiled into a <Link to='/articles/automated-latency-monitoring-superbowl-2025/'>post-event report</Link>, providing exclusive data and valuable insights into the evolving challenges of large-scale live streaming. Available on <Link to="/">witbe.net</Link> and <a href="https://tagvs.com/" rel='external' target='_blank'>tagvs.com</a>.</p>


        <h2>About TAG Video Systems</h2>
        <p>TAG Video Systems is the global leader in software-based integrated IP probing, monitoring, visualization and analytics solutions. TAG enables broadcasters, content creators, and service providers to streamline operations, cut through the complexity of IP media workflows, and keep infrastructures ahead of shifting technology and demand with Zero Friction agility. The TAG platform empowers users with innovative toolsets to ensure video quality, improve overall efficiency across all media workflows, and provides invaluable business and operational insights. Learn more at <a href="https://tagvs.com/" rel='external' target='_blank'>www.tagvs.com</a>. </p>


        <h2>About Witbe</h2>
        <p>Witbe (Euronext Growth - FR0013143872 - ALWIT) provides automated real-time testing and proactive monitoring technology for global video service providers. The company’s award-winning technology accurately measure the quality of experience that viewers actually receive at the end of the video delivery path, ensuring flawless video service on any device, over any network, across the world. Witbe’s non-intrusive technology replicates user behavior enabling broadcasters, cable operators and streaming service providers to automatically test and monitor video services from anywhere in the world. Witbe’s technology is trusted by major brands worldwide, including Comcast, Cox, Verizon, and Peacock. Learn more at <Link to="/">www.witbe.net</Link>.</p>
    </React.Fragment>
);
export default Content;
