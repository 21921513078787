import React from 'react';
import { useTranslation } from 'react-i18next';

import endUsers from '../../images/resources/Screenshot29.png';
import portal from '../../images/resources/Screenshot37.png';
import smartping from '../../images/resources/Screenshot14.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t('siteMetadata.resources.articles.webAndSmartphone.h2')}
            </h2>
            <figure>
                <img src="/articles/old/Web_and_Smartphone_Applications_Monitoring.jpg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <div className={newSnapshots.bodyTextWithBorder}>
                <span></span>
                <p className={newSnapshots.bodyTextWithPargin}>
                    <p>{t('siteMetadata.resources.articles.webAndSmartphone.mainText1')}</p>
                    <p>{t('siteMetadata.resources.articles.webAndSmartphone.mainText2')}</p>
                </p>
            </div>
            <figure>
                <img src={endUsers} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.webAndSmartphone.sectionATitle')}</h2>
            <p>{t('siteMetadata.resources.articles.webAndSmartphone.sectionAContent1')}</p>
            <p>{t('siteMetadata.resources.articles.webAndSmartphone.sectionAContent2')}</p>
            <figure>
                <img src={portal} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.webAndSmartphone.sectionBTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.webAndSmartphone.sectionBContent1')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.webAndSmartphone.sectionBContent2')}}></p>
            <figure>
                <img src={smartping} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.webAndSmartphone.sectionCTitle')}</h2>
            <p>{t('siteMetadata.resources.articles.webAndSmartphone.sectionCContent1')}</p>
            <p>{t('siteMetadata.resources.articles.webAndSmartphone.sectionCContent2')}</p>
            <p>{t('siteMetadata.resources.articles.webAndSmartphone.sectionCContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
