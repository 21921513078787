import React from 'react';

import Link from '../common/Link';

import styles from '../../templates/styles/blogpost.module.scss';

const Content = () => (
    <React.Fragment>
        <p>Most video service providers rely on a Network Operations Center (NOC) where Video Operations teams monitor the source stream of their broadcasts.</p>
        <p>This is helpful for providers to <b>get a comprehensive view of video service's performance.</b> However, the video quality that is sent out is not always the same quality received by viewers. Witbe&rsquo;s virtual NOC, the Remote Eye Controller (REC), was built to address this challenge.</p>
        <p><b>A virtual NOC compiles live streams from local real testing devices, </b><Link to="/technology/supported-devices/"><b>like smartphones, smart TVs, STB</b></Link><b>, in a web app. </b>It empowers providers to <b>remotely access and control </b><b>any device types</b> worldwide, bringing global visibility.</p>
        <p>For more on Witbe&rsquo;s virtual NOC, check out our <Link to="/articles/what-is-a-virtual-noc/">introduction article here</Link>.</p>

        <h2>What is a virtual NOC and what does it do?</h2>
        <p>For video service providers, <b>analyzing the true experience that viewers receive</b> is challenging but crucial. While source streams, traditional NOCs, and Quality of Service (QoS) metrics offer insight into the network performance of a video service, they do not reflect what viewers see and experience locally: the Quality of Experience (QoE).</p>
        <p>Witbe&rsquo;s virtual NOC can <Link to="/technology/video-service-monitoring/">monitor the true Quality of Experience that users receive</Link>. The REC is a virtual Network Operations Center that allows teams to access real testing devices across the world. With it, you can <b>test and monitor your service&rsquo;s performance</b><b>on real devices and networks</b>, at the furthest point in the content delivery chain. With Witbe, you can <b>easily test and monitor viewer behavior</b> as a human &ndash; 24/7, 365, from anywhere in the world.</p>

        <h2>Witbe&rsquo;s virtual NOC: Benefits, key features, and capabilities</h2>

        <h3>1- Real-time testing anywhere you need</h3>
        <p>
            <b>Test, monitor, and take control of any video app or service on real devices.</b>
            <br/>Local test devices can be set up anywhere in the world where your service is operating. Remotely access your stream and service on real testing devices 24/7.
        </p>
        <p>With <Link to="/technology/qa-test-automation/">Witbe&rsquo;s award-winning automated testing technology</Link>, you can proactively monitor KPIs day and night. You can accurately <b>analyze the video service you are delivering to your viewers</b>, since <Link to="/products/witbox/">the Witbox monitors real devices</Link> and networks. The Witbox <b>replicates human behavior to test as a normal user</b> and see the service viewers receive, even on DRM-protected content.</p>
        <figure>
            <img src='/articles/2024-12-09-noc-benefits/PM_asset-articles-content-landscape1.jpg' alt='Remote control on smart tv - Roku TV' className={styles.smallImage} />
        </figure>

        <h3>2- Display an unlimited number of devices in the mosaic</h3>
        <p>
            The REC features a <b>mosaic display of live streams</b> from all your testing devices connected to a Witbox.
            <br/> It can display every testing device, live channel, VoD asset, and live event on one screen. It&rsquo;s easily customizable according to your preferences, so you can find the information you need to follow up on at a glance. Take control of any device at any time from your Virtual NOC!
        </p>
        <figure>
            <img src='/articles/2024-12-09-noc-benefits/PM_asset-articles-content-landscape2.jpg' alt='Witbe’s virtual NOC mosaic' className={styles.smallImage} />
        </figure>

        <h3>3- Shared technology empowering your team to speak the same language</h3>
        <p>
            Need to run a new test scenario to confirm that an issue has been resolved?
            <br/>You can do that directly in the REC, <b>a live monitoring app accessible by all your teams from any web browser.</b>
        </p>
        <p>With a shared workspace, all team members can &ldquo;speak the same language&rdquo; and easily keep track of your service&rsquo;s live status &ndash; even when it&rsquo;s as specific as an engineer saying: &ldquo;I took control of the Smart TV in San Francisco and launched a new scenario to check if the catalog issue is resolved&rdquo;.</p>

        <h3>4- Launching automated test scenarios</h3>
        <p>
            Witbe&rsquo;s technology allows team members to <b>launch preset automated test scenarios</b> directly from the REC.
            <br/>In the &ldquo;Scenario&rdquo; panel, you can launch scenarios and trigger tests. It&rsquo;s easy to <b>test the real-time availability of your TV channels</b>, <b>ensure your live events are being streamed correctly</b>, or <b>verify the full availability of your VoD catalog </b>on a wide range of real devices.
        </p>
        <p>Directly from the REC, <b>you can trigger a test case on your video service</b>. You&rsquo;ll see <b>live test results</b> in the mosaic with <b>instant alerts when your video service has an issue</b>.</p>

        <h3>5- Receive instant streaming service alerts and KPIs</h3>
        <p>When running an pre-set test case, such as <b>checking the VoD availability</b> and <b>streaming quality</b> of a specific episode on a real device, you can see the test results and KPI metrics in real time in the REC.</p>
        <p>For a deeper analysis, you can <b>get all your monitoring data and results</b> in Smartgate, Witbe&rsquo;s <b>observability platform</b>.</p>
        <figure>
            <img src='/articles/2024-12-09-noc-benefits/PM_asset-articles-content-landscape3.jpg' alt='Witbe’s virtual NOC mosaic' className={styles.smallImage} />
        </figure>

        <h2>Comparing virtual and traditional NOCs</h2>
        <p>A traditional NOC is a mosaic of source streams, while Witbe&rsquo;s virtual NOC is a mosaic of local end-user live streams captured on real devices.</p>
        <p>
            A virtual NOC is also a <b>video monitoring shared digital workspace</b>, making your <Link to="/technology/remote-device-access/"><b>manual and automated tests</b></Link><b> on your video services </b>more accessible and flexible.
            <br/>You can easily switch from one device to another, <b>take control of any local devices remotely</b>, and verify that everything is running smoothly at a glance.
        </p>
        <p>
            The REC is also easy to deploy everywhere in the world, even for global teams working in several different countries and markets.
            <br/>It&rsquo;s as simple as connecting your test devices to a Witbox and launching the REC on any web browser. You will quickly be able to see all of your devices on one screen and <b>start monitoring your video service.</b>
        </p>
        <p>
            The virtual NOC is your best ally to <b>increase monitoring efficiency, improve testing capabilities, and keep viewers loyal to your service by delivering the optimal user experience.</b>
            <br/>It gives you eyes and ears on <b>exactly what your viewers are seeing at all times.</b>
        </p>
        <p>When paired with a traditional NOC, teams can quickly identify differences between them to resolve errors with ease. It fills a critical blind spot. A virtual NOC helps you do better!</p>

        <h2>Customer spotlight: MSCTV&rsquo;s virtual NOC</h2>
        <p>
            Mitchell Seaforth Cable TV (<b>MSCTV</b>) is a leading Canadian provider of unique solutions to telecommunications companies.
            <br/>They were recently <b>looking to monitor their video service quality and wanted to provide a superior experience to linear TV application viewers.</b>
        </p>
        <p>Why did they choose Witbe&rsquo;s virtual NOC <b>to test and monitor the features and content of their Pay-TV services, ensuring it&rsquo;s presented to their customers with outstanding quality? </b></p>

        <ul>
            <li>They can <b>test their service on real devices and see exactly what their end-users see.</b> With the Witbox<sup>+</sup>, Witbe&rsquo;s robot, they can <b>remotely test on local Set-Top Boxes in real conditions.</b></li>
            <li>With the REC, they can <b>control their local devices in the field to ensure they are working everywhere.</b></li>
        </ul>

        <p>Now, thanks to Witbe, they have eyes and ears everywhere.</p>
        <p>Don&rsquo;t just take our word for it: <b>Michael Hawkes,</b> General Manager at MSCTV, says “Video quality is a fundamental pillar of success at MSCTV. Our partnership with Witbe ensures that we can meet and exceed our customers' expectations for an <b>exceptional linear viewing experience.”</b></p>
        <p>Read more about <Link to="/articles/msctv-partnership-virtual-noc-live-monitoring/">MSCTV and Witbe&rsquo;s virtual NOC here</Link>.</p>
        <figure>
            <img src='/articles/2024-12-09-noc-benefits/PM_asset-articles-content-landscape4.jpg' alt='Witbe and MSCTV partnership' className={styles.smallImage} />
        </figure>

        <h2>Check out a virtual NOC today</h2>
        <p>As video service providers aim to <b>meet high viewer expectations,</b> the advanced capabilities, flexibility, and accessibility of a virtual NOC offers teams a <Link to="/technology/"><b>superior video testing solution</b></Link>. It&rsquo;s perfect for video service providers of all sizes, all over the world.</p>

        <h2>Read more about Witbe&rsquo;s virtual NOC</h2>

        <ul>
            <li>TVB Europe: <Link to="https://www.tvbeurope.com/media-delivery/witbe-launches-virtual-network-operation-centre-for-video-service-providers" rel="external">Witbe launches virtual network operation centre for video service providers</Link></li>
            <li>TV News Check: <Link to="https://tvnewscheck.com/tech/article/witbe-launches-new-virtual-noc" rel="external">Witbe Launches New Virtual NOC</Link></li>
            <li>TV Tech: <Link to="https://www.tvtechnology.com/news/canadas-msctv-chooses-witbe-virtual-noc-for-monitoring" rel="external">Canada&rsquo;s MSCTV Chooses Witbe Virtual NOC for Monitoring</Link></li>
        </ul>

        <p>Read our latest <b>press release </b><Link to="/articles/new-virtual-noc-launch-press-release/"><b>announcing Witbe&rsquo;s virtual NOC</b></Link><b>.</b></p>
        <p>To learn more about Witbe&rsquo;s technology, <Link to="/contact-us/"><b>contact us</b></Link><b>.</b></p>
    </React.Fragment>
);
export default Content;
