import React from 'react';
import { useTranslation } from 'react-i18next';

import workbench from '../../images/resources/Screenshot29.png';
import portal from '../../images/resources/Screenshot30.png';
import smartping from '../../images/resources/Screenshot31.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.articles.businessApplications.h2')}</h2>
            <figure>
                <img src='/articles/old/Business_Applications_Performance_Monitoring.jpg' id={newSnapshots.mainImg} alt='Witbe Smartgate' />
            </figure>
            <p>{t('siteMetadata.resources.articles.businessApplications.mainText1')}</p>
            <p>{t('siteMetadata.resources.articles.businessApplications.mainText2')}</p>

            <h3>{t('siteMetadata.resources.articles.businessApplications.sectionATitle')}</h3>
            <figure>
                <img src={workbench} alt='Witbe Workbench' />
            </figure>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionAContent1')}</p>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionAContent2')}</p>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionAContent3')}</p>

            <h3>{t('siteMetadata.resources.articles.businessApplications.sectionBTitle')}</h3>
            <figure>
                <img src={portal} alt='Witbe Smartgate' />
            </figure>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionBContent1')}</p>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionBContent2')}</p>

            <h3>{t('siteMetadata.resources.articles.businessApplications.sectionCTitle')}</h3>
            <figure>
                <img src={smartping} alt='Witbe Workbench' />
            </figure>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionCContent1')}</p>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionCContent2')}</p>
            <p>{t('siteMetadata.resources.articles.businessApplications.sectionCContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
