import React from 'react';

import Link from '../common/Link';

import styles from '../../templates/styles/blogpost.module.scss';

const Content = () => (
    <React.Fragment>
        <p className={styles.marginSm}><b>NEW YORK — Feb. 19, 2025 —</b> Witbe (Euronext Growth – FR0013143872 – ALWIT), a global leader in test automation and monitoring technology for video service providers, today announced a new collaboration with the <b>National Content &amp; Technology Cooperative (NCTC)</b> – the organization representing independent broadband network operators across the United States, allowing them to collectively negotiate for programming and technology access with content providers and hardware manufacturers.</p>
        <p>As an NCTC Approved Partner, Witbe will bring its award-winning video service quality monitoring solutions to the NCTC Marketplace, enabling independent video providers across North America to access and leverage its technology more easily and affordably.</p>

        <p>To kickstart this new partnership, Witbe will exhibit at the <b>2025 NCTC Winter Educational Conference taking place from Feb. 23-25 in Atlanta at <Link to='/events/nctc-winter-educational-conference-2025/'>booth #214</Link></b>. The event serves as a premier gathering for industry leaders, offering an opportunity for NCTC members to connect with Witbe and see how its solutions enhance video service performance, user experience, and operational efficiency.</p>

        <p className={styles.marginSm}>Major service providers worldwide trust <Link to='/technology/'>Witbe’s non-intrusive technology</Link> for its ability to give them the visibility to what they are delivering on their subscribers’ screens; to remotely take control of their video streams; automate QA testing and real-time monitoring of video services on real testing devices.</p>
        <p>The company’s <b>virtual NOC – the Remote Eye Controller (REC) web application</b> – and its Witbox product line empower operators to instantly detect and efficiently resolve quality issues, ensuring seamless video delivery to viewers.</p>

        <p className={styles.marginSm}>This collaboration delivers multiple benefits to NCTC and its members:</p>
        <ul>
            <li><b>Monitoring Service Quality:</b> NCTC members can leverage Witbe&#39;s REC Virtual NOC to proactively monitor channel availability across all devices and networks, allowing them to detect issues, such as channel crashes, before their viewers do.</li>
            <li><b>Driving Innovation:</b> Witbe’s products support NCTC&#39;s vision of fostering growth and innovation by equipping its members with advanced AI powered robots to test and measure the real-world performance of their video services. By providing insights across locations and real devices, Witbe empowers NCTC members to stay competitive in an ever-evolving media landscape.</li>
            <li><b>Simplifying Technology Procurement:</b> Through NCTC&#39;s cooperative model, independent service providers can more easily access Witbe’s innovative technology and remotely access and take control of their test devices - i.e., save on truck roll and collaborate to fix service errors efficiently and fast.</li>
        </ul>

        <blockquote>
            “We are thrilled to join NCTC and support its community of independent video providers,” said <b>Marie-Véronique Lacaze, President at Witbe</b>. “With video quality expectations higher than ever and the increasing performance of streaming video services, our cutting-edge technology empowers operators to stay ahead of the curve and optimize their services across all devices, networks, and locations. Our team looks forward to connecting with NCTC members in Atlanta and exploring ways to enhance their ability to deliver the best possible viewing experiences to their customers.”
        </blockquote>

        <p>For more information about Witbe and our solutions, <Link to='/contact-us/'>contact us today</Link>.</p>
    </React.Fragment>
);
export default Content;
