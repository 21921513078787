import React from 'react';
import PropTypes from 'prop-types';

import styles from './flex-images.module.scss';

const FlexImages = ({
    leftPicture,
    rightPicture,
}) => (
    <div className={styles.flexImages}>
        <figure>
            <img src={leftPicture.img} alt={leftPicture.alt} />
        </figure>
        <figure>
            <img src={rightPicture.img} alt={rightPicture.alt} />
        </figure>
    </div>
);

FlexImages.propTypes = {
    leftPicture: PropTypes.objectOf({
        img: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
    }).isRequired,
    rightPicture: PropTypes.objectOf({
        img: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
    }).isRequired,
};
export default FlexImages;
