import React from 'react';
import { useTranslation } from 'react-i18next';

import SodexoSVG from '../../images/resources/SodexoSVG.svg';

import styles from '../../templates/styles/blogpost.module.scss';
import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <figure>
                <img src='/articles/old/SodexoBanner.png' id={newSnapshots.mainImg} alt='Witbe Remote Eye Controller' />
                <img src={SodexoSVG} className={styles.smallImage} alt='Sodexo logo' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.sodexo.introContent1`)}}></p>

            <h2>{t(`siteMetadata.resources.snapshots.sodexo.challengesTitle`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.sodexo.challengesContent1`)}</p>
            <blockquote>{t(`siteMetadata.resources.snapshots.sodexo.changesQuote`)}</blockquote>

            <h2>{t(`siteMetadata.resources.snapshots.sodexo.changesTitle`)}</h2>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.sodexo.changesContent1`)}} />
            <blockquote dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.sodexo.situationQuote`)}} />

            <h2>{t(`siteMetadata.resources.snapshots.sodexo.situationTitle`)}</h2>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.sodexo.situationContent1`)}} />

            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.sodexo.reachOut`)}} />
        </React.Fragment>
    )
}

export default Content;
