import React from 'react';

const Content = () =>
    <>
        <h2>Enhancing Automated Quality Assurance with Witbox+</h2>
        <p>
            We announced today that French operator Bouygues Telecom is strengthening its long-running partnership with Witbe by upgrading its QA testing equipment to the
            {' '}
            <a href='/products/witbox/' alt='Latest powerful models of the Witbox+' target='_blank'>latest powerful models of the Witbox+</a>.
            {' '}
            Bouygues Telecom is utilizing Witbe's new technology to deliver video services more efficiently, with a superior Quality of Experience for viewers.
        </p>
        <p>
            "Witbe's technology allows us to detect issues in the lab before they appear in production, optimizing our Time To Market and ensuring the best possible Quality of Experience is delivered to our customers,"
            {' '}
            said Loubna Hamdoun, Head of Product and Service Integration and Validation for Fixed Services at Bouygues Telecom.
        </p>
        <figure>
            <img src='/articles/2024-09-26-bouygues/bt-asset-articles-content-landscape.jpg' alt='Witbe X Bouygues Telecom' />
        </figure>
        <p>
            Bouygues Telecom has been using Witbe's technology since 2018 and is now supplied with the latest cutting-edge version of the Witbox+ hardware and Witbe Software Suite technology.
            {' '}
            Integrating the Witbox+'s
            {' '}
            <a href='/technology/qa-test-automation/' alt='Automated testing technology' target='_blank'>automated testing technology</a>
            {' '}
            significantly increases Bouygues Telecom's Quality Assurance capabilities for video app performance on Set-Top Boxes (STBs), Smart TVs, and HDMI-connected streaming media devices.
        </p>
        <p>
            The Witbox+ boosts Bouygues Telecom's testing and monitoring capabilities by allowing team members to easily script new test scenarios, automate complex performance testing, reduce test scenario maintenance
            {' '}
            <a href='/articles/smart-navigate-ai-test-automation-for-video-service-providers/' alt='With the help of AI algorithms' target='_blank'>with the help of AI algorithms</a>
            , quickly test 4K assets,
            {' '}
            and analyze all test results through streamlined reports on Witbe's Smartgate observability platform.
            {' '}
            The Witbox+ can power through automated tests that are impossible to perform manually, such as changing a channel thousands of times, continually restarting STBs, or identifying video errors after hours of nonstop viewing.
        </p>
        <p>
            "We are very happy to continue our partnership with Bouygues Telecom and help contribute to their tremendous success by providing them with cutting-edge video testing and monitoring technology,"
            {' '}
            said Marie-Véronique Lacaze, President and Co-Founder of Witbe.
            {' '}
            "This deployment underscores our mission to ensure that operators like Bouygues Telecom have reliable and innovative tools to deliver exceptional experiences to their users."
        </p>
        <p>
            To learn more about the Witbox+ and the rest of our technology,
            {' '}
            <a href='/contact-us/' target='_blank' alt='Contact us today'>contact us today</a>.
        </p>
        <h2>About Bouygues Telecom</h2>
        <p>
            A subsidiary of the Bouygues Group, Bouygues Telecom is a global French operator of communications and digital services, generating an annual revenue of €7.7 billion with 10,500 employees and 519 stores across France.
            {' '}
            Founded in 1994, Bouygues Telecom is committed to providing individuals, businesses, and public administrations with fixed and mobile communication services, as well as high-speed, secure, innovative, and quality internet services by constantly developing its network and user experience.
        </p>
        <p>
            Bouygues Telecom serves 23.9 million mobile customers and 5 million fixed customers, making it the leading provider of Wi-Fi and fixed internet connections in 2023, according to Nperf, and the second largest mobile operator, according to ARCEP.
            {' '}
            Its 4G network covers 99% of the French population, while its 5G network covers nearly 17,000 municipalities and more than 81% of the population. Bouygues Telecom Enterprises supports nearly 100,000 clients, including 70% of the CAC 40, in adopting new collaborative tools, migrating to the cloud, and transforming their digital infrastructures.
            {' '}
            Committed to reducing its carbon emissions, Bouygues Telecom aims to achieve -29.4% for scopes 1 and 2 and -17.5% for scope 3 by 2027, with goals approved by the Science Based Targets initiative.
        </p>
    </>;

export default Content;
