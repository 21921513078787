import React from 'react';

import Link from '../common/Link';

import styles from '../../templates/styles/blogpost.module.scss';

const Content = () => (
    <React.Fragment>
        <h2>Recognizing excellence in video testing and monitoring</h2>
        <p>Witbe is proud to announce that its <Link to='/articles/what-is-a-virtual-noc/'>Virtual Network Operations Center (NOC) technology</Link> has been awarded a <b>4-diamond rating</b> in the prestigious <b>Lightwave and BTR Innovation Reviews 2025</b>. This recognition underscores our commitment to revolutionizing <b>video testing, video monitoring, and quality assurance</b> for operators and content providers worldwide.</p>

        <h2>The challenges of video service monitoring in a streaming-first world</h2>
        <p className={styles.marginSm}>With the rise of streaming services, video providers face increasing complexities in ensuring seamless content delivery. The diversity of devices, operating systems, video delivery methods, and DRM systems makes traditional monitoring solutions inadequate. <b>Video quality testing and device testing</b> on real consumer devices is no longer optional—it’s essential.</p>
        <p>Witbe’s <b>Virtual NOC, powered by our Remote Eye Controller (REC),</b> addresses this challenge head-on. It offers unparalleled visibility into real device performance, allowing teams to <b>monitor, test, and troubleshoot</b> streaming issues from anywhere in the world—all within a secure, browser-based environment.</p>

        <h2>What sets Witbe’s virtual NOC apart?</h2>
        <p>Unlike traditional NOCs that only monitor the source stream, Witbe’s Virtual NOC provides <b>end-to-end visibility</b> into the viewer’s actual experience. Here’s how <Link to="/articles/benefits-of-virtual-noc/">our award-winning technology stands out</Link>:</p>
        <ul>
            <li><b>Remote Access to Real Devices</b> – Teams can instantly interact with actual consumer devices, from set-top boxes to Smart TVs, gaming consoles, and mobile devices, ensuring accurate <b>device testing</b>.</li>
            <li><b>Real-Time KPIs and Alerts</b> – Activate automation for immediate performance data and <b>test results</b> to allow providers to detect and resolve issues before they impact <b>viewers.</b></li>
            <li><b>Enterprise-Grade Security</b> – Secure login with Single Sign-On (SSO) and per-user Access Control Lists (ACL) ensures safe, controlled access.</li>
            <li><b>Scalable and Collaborative</b> – A unified, web-based workspace enables multiple teams to work together efficiently, reducing incident resolution time from days to minutes.</li>
        </ul>

        <img src='/articles/2025-02-27-lightwave-btr-award/virtual-noc-vod-instant-error-check.png' alt='virtual noc vod instant error check' />

        <h3>Advanced features of the virtual NOC</h3>
        <p>Witbe’s Virtual NOC enhances video service monitoring with powerful capabilities:</p>
        <ul>
            <li><b>Take Control</b> – Remotely operate and test devices as a real user, using the remote, voice control, and AI-powered features such as Smart Search.</li>
            <li><b>Recording</b> – Capture and share video streams instantly, ensuring teams work with the same data for faster issue resolution.</li>
            <li><b>NOC Display</b> – Monitor up to 156 devices in 4K, maximizing visibility across all streams especially in Network Operation Centers</li>
            <li><b>Personalization</b> – Customize the interface with favorites, dynamic clusters, and adjustable grid sizes for efficient navigation and tailored operations</li>
            <li><b>KPIs Alerts</b> – Instantly spot errors with a red overlay on affected devices in the mosaic view, allowing for rapid response.</li>
        </ul>

        <img src='/articles/2025-02-27-lightwave-btr-award/virtual-noc-4k-mosaic-full-screen.png' alt='virtual noc 4k mosaic full screen' />

        <h3>Real-world impact: Transforming video service operations</h3>
        <p>Our Virtual NOC has already demonstrated significant benefits for major video service providers. For one leading cable operator managing multiple legacy systems across regions, the REC reduced issue resolution times from <b>days to minutes</b>, improving both operational efficiency and customer satisfaction.</p>
        <p>Additionally, by enabling <b>proactive issue detection and automation</b>, the platform helped decrease a persistent error rate from <b>3% to just 0.3%</b>. As one satisfied customer put it: <em>&quot;Now, I see what I was blind to before – I can finally see what my viewers see”.</em></p>

        <h2>Looking ahead: A future-ready solution for video providers</h2>
        <p>Earning a <b>4-diamond rating</b> in the 2025 Innovation Reviews is a testament to Witbe’s dedication to <Link to="/technology/">video quality testing, automation, and customer-centric solutions</Link>. As streaming continues to evolve, our Virtual NOC remains the <b>go-to solution</b> for video service providers seeking <b>unmatched video testing, video monitoring, and automation capabilities</b>.</p>

        <h2>Discover how Witbe can elevate your video service monitoring</h2>
        <p>Are you ready to experience the power of <b>real-device testing, automation, and proactive issue detection</b>?</p>
        <p><Link to="/contact-us/">Contact us today!</Link></p>
    </React.Fragment>
);
export default Content;
