import React from 'react';
import { useTranslation } from 'react-i18next';

import whatMatters from '../../images/resources/Screenshot19.jpg';
import singleInterface from '../../images/resources/Screenshot20.png';
import alerting from '../../images/resources/Screenshot21.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <h2>{t(`siteMetadata.resources.articles.voiceServices.h2`)}</h2>
            <figure>
                <img src='/articles/old/Voice_Services.jpg' id={newSnapshots.mainImg} alt='Witbe Smartgate' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.voiceServices.mainText1`)}</p>
            <p>{t(`siteMetadata.resources.articles.voiceServices.mainText2`)}</p>
            <p>{t(`siteMetadata.resources.articles.voiceServices.mainText3`)}</p>

            <h3>{t(`siteMetadata.resources.articles.voiceServices.sectionATitle`)}</h3>
            <figure>
                <img src={whatMatters} alt='Witbox'/>
            </figure>
            <p>{t(`siteMetadata.resources.articles.voiceServices.sectionAContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.voiceServices.sectionBTitle`)}</h3>
            <figure>
                <img src={singleInterface} alt='Witbe Smartgate'/>
            </figure>
            <p>{t(`siteMetadata.resources.articles.voiceServices.sectionBContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.voiceServices.sectionCTitle`)}</h3>
            <figure>
                <img src={alerting} alt='Witbe Smartgate'/>
            </figure>
            <p>{t(`siteMetadata.resources.articles.voiceServices.sectionCContent1`)}</p>
        </React.Fragment>
    )
}

export default Content;
