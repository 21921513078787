import React from 'react';

const Content = () => (
    <React.Fragment>
        <h2>{'Witbe\'s Expansion in Dubai'}</h2>
        <p>
            {' We announced today that Witbe is expanding its presence in the Middle East and Africa (MEA) with a dedicated office location in Dubai.'}
            {' The new office officially opened its doors on October 15th, 2024, with a grand opening ceremony led by Witbe\'s President and Co-Founder, Marie-Véronique Lacaze.'}
        </p>
        <h2>{'A Word From Witbe\'s President and Co-Founder'}</h2>
        <p>
            {'"We are incredibly proud of the success of our local Dubai team and are excited to expand Witbe\'s support for video service providers in the MEA region with this new dedicated office," said Lacaze.'}
        </p>
        <p>
            {'"Opening the MEA location comes at an exciting time, as our virtual network operation center technology empowers video service providers to '}
            <a href='/technology/remote-device-access/' target='_blank'>
                easily monitor and control all their local testing devices
            </a>
            {' at any time from any web browser."'}
        </p>
        <figure>
            <img
                src='/articles/2024-10-24-dubai-new-office/Dubai-Office-Opening-Website_asset-articles-content-landscape.jpg'
                alt='Dubai office opening'
            />
        </figure>
        <h2>The MEA Region Growing Video Streaming Market</h2>
        <p>
            {'The MEA region is one of the fastest growing video streaming markets in the world.'}
            {' Witbe began working in the region in 2015 and has been actively operating in Dubai since 2017.'}
        </p>
        <p>
            {'The company\'s new location is fully staffed with team members across sales, engineering, customer success, and business development, demonstrating Witbe\'s'}
            {' strong commitment to the region\'s video streaming market and continued investment in working closely with local customers.'}
        </p>
        <h2>Witbe's Virtual Network Operation Center (NOC) Technology</h2>
        <p>
            {'Witbe\'s technology allows providers to '}
            <a href='/technology/' target='_blank'>test and monitor their video services on real devices</a>
            {' in the lab or field and access them from anywhere in the world through a virtual Network Operation Center.'}
        </p>
        <p>
            When proactive monitoring sessions encounter a video error, providers are immediately notified so they can confirm and resolve the issue as quickly as possible.
        </p>
        <p>
            {'Witbe\'s '}
            <a href='/technology/qa-test-automation/' target='_blank'>
                automated QA testing technology
            </a>
            {' has also been widely adopted in the region, helping providers deliver the best Quality of Experience possible to stand out in a competitive market.'}
        </p>
        <p>
            {'For more information on Witbe\'s virtual NOC technology, '}
            <a href='/contact-us/' target='_blank' alt='Contact us'>
                contact us today
            </a>
            .
        </p>
    </React.Fragment>
);

export default Content;
