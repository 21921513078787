import React from 'react';

import Link from '../common/Link';

const Content = () => (
    <React.Fragment>
        <p>Marie-Véronique Lacaze, Witbe’s President and Co-Founder, was recognized with a People’s Choice Award by <a href="https://online.bright-publishing.com/view/282866517/19/" rel="external" target='_blank'>the FEED Honours List 2024</a>.</p>
        <p>Lacaze was awarded in the Pioneering Women category. FEED Magazine wrote that their mission for the Honours List was to “give back by celebrating the extraordinary talent we witness, both within FEED’s own pages as well as face-to-face at conferences and shows throughout each year.” The People’s Choice Awards were created to “shine a spotlight on the leaders that deserve acknowledgement for their trailblazing impact on this industry.”</p>
        <p>In their write-up about Marie-Véronique Lacaze, the <b>FEED Honours List noted</b> that she “has been a trailblazer in shaping broadcast and media tech for three decades, particularly in measuring Quality of Experience. Her dynamic leadership has guided Witbe to become a global leader in automated testing and proactive monitoring tech for video service providers.”</p>
        <p>As Witbe commented, “Not only is Marie-Véronique Lacaze one of only twenty women in history to have launched a tech company public, but under her hands-on leadership, Witbe has doubled in size and revenue. In an industry that remains male-dominated, her achievements and contributions to <Link to="/technology/">the past, present, and future of technology</Link> and her innovative leadership make her a Pioneering Woman setting an example for all to see.”</p>
        <p>For more information on Marie-Véronique Lacaze and her history, including Witbe’s origins, check out the full <a href="https://online.bright-publishing.com/view/282866517/19/" rel="external" target='_blank'>FEED Honours List here</a>.</p>
        <p>To learn more about Witbe’s <Link to="/articles/what-is-a-virtual-noc/">automated testing and monitoring technology</Link> for video service providers</p><Link href="/contact-us/">Get in touch today!</Link>
     </React.Fragment>
);
export default Content;
