import React from 'react';
import { useTranslation } from 'react-i18next';

import banner from '../../images/resources/banners/OTT_UK_Banner.jpeg';
import OTT_UK1 from '../../images/resources/OTT_UK1.png';
import OTT_UK2 from '../../images/resources/OTT_UK2.png';
import OTT_UK3 from '../../images/resources/OTT_UK3.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUK.introTitle')}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUK.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUK.introContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUK.introContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUK.introContent4')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.ul1.ulItem1') }} />
                <li dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.ul1.ulItem2') }} />
                <li dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.ul1.ulItem3') }} />
                <li dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.ul1.ulItem4') }} />
            </ul>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUK.introContent5')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.introContent6') }} />
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUK.overviewTitle')}</h2>
            <figure>
                <img src={OTT_UK1} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.overviewContent1') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.overviewContent2') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.overviewContent3') }} />
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUK.primeTimeTitle')}</h2>
            <figure>
                <img src={OTT_UK2} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.primeTimeContent1') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.primeTimeContent2') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.primeTimeContent3') }} />
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUK.bufferingTitle')}</h2>
            <figure>
                <img src={OTT_UK3} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.bufferingContent1') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.bufferingContent2') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.bufferingContent3') }} />
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUK.conclusionTitle')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.conclusionContent1') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.conclusionContent2') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.conclusionContent3') }} />
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUK.QOESnapshotsTitle')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.QOESnapshotsContent1') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.QOESnapshotsContent2') }} />
            <p dangerouslySetInnerHTML={{ __html: t('siteMetadata.resources.snapshots.bestOTTAppUK.QOESnapshotsContent3') }} />
        </React.Fragment>
    );
}

export default Content;
