import React from 'react';
import { useTranslation } from 'react-i18next';

import snapshotRobot from '../../images/resources/Snapshot.png';
import deepDive1 from '../../images/resources/deep_dive_1.png';
import deepDive2 from '../../images/resources/deep_dive_2.png';
import deepDive3 from '../../images/resources/deep_dive_3.png';
import deepDive4 from '../../images/resources/deep_dive_4.png';
import SnapshotVideo from '../../images/resources/Deep_Dive_Video_Error.mp4';

import styles from '../../templates/styles/blogpost.module.scss';
import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.videoConferencing.about1`)}}></p>
            <figure>
                <img src='/articles/old/Deep_Dive_Banner.png' id={newSnapshots.mainImg} alt='Witbe Smartgate' />
            </figure>

            <h2 style={{ marginBottom: 0 }}>{t(`siteMetadata.resources.snapshots.videoConferencing.introTitle`)}</h2>
            <img src={snapshotRobot} alt='Witbe QoE Robot logo' className={styles.smallImage}/>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.videoConferencing.introContent1`)}}></p>


            <h2>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Title`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Content1`)}</p>

            <video controls width="100%" style={{ borderRadius: "15px" }}>
                <source src={SnapshotVideo} type="video/mp4" />
                {t(`siteMetadata.resources.snapshots.stadia.videoErrorMessage`)}
            </video>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Content2`)}</p>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Content3`)}}></p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Content4`)}</p>
            <figure>
                <img src={deepDive1} alt='Cisco Webex' style={{ width: '650px', margin: '0 auto' }} />
                <figcaption>Successful test</figcaption>
            </figure>
            <figure>
                <img src={deepDive2} alt='Cisco Webex' style={{ width: '650px', margin: '0 auto' }} />
                <figcaption>Failed test</figcaption>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Content5`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive1Content6`)}</p>

            <h2>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Title`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content1`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content2`)}</p>
            <blockquote>{t(`siteMetadata.resources.snapshots.stadia.overviewQuote`)}</blockquote>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content3`)}</p>
            <figure>
                <img src={deepDive3} alt='Cisco Webex' style={{ width: '650px', margin: '0 auto' }} />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content4`)}}></p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content5`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content6`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content7`)}</p>
            <figure>
                <img src={deepDive4} alt='Cisco Webex' style={{ width: '650px', margin: '0 auto' }} />
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content8`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive2Content9`)}</p>

            <h2>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Title`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content1`)}</p>
            <blockquote>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Quote`)}</blockquote>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content2`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content3`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content4`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content5`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content6`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.deepDive3Content7`)}</p>

            <h2>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyTitle`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent1`)}</p>
            <ul>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul1.ulItem1`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul1.ulItem2`)}</li>
            </ul>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent2`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent3`)}</p>
            <ul>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul2.ulItem1`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul2.ulItem2`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul2.ulItem3`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul2.ulItem4`)}</li>
            </ul>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent4`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent5`)}</p>
            <ul>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul3.ulItem1`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul3.ulItem2`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul3.ulItem3`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul3.ulItem4`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul3.ulItem5`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul3.ulItem6`)}</li>
            </ul>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent6`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent7`)}</p>
            <ul>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul4.ulItem1`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul4.ulItem2`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul4.ulItem3`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul4.ulItem4`)}</li>
            </ul>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent8`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent9`)}</p>
            <ul>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul5.ulItem1`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul5.ulItem2`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul5.ulItem3`)}</li>
                <li>{t(`siteMetadata.resources.snapshots.videoConferencing.ul5.ulItem4`)}</li>
            </ul>
            <p>{t(`siteMetadata.resources.snapshots.videoConferencing.methodologyContent10`)}</p>

            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.videoConferencing.contactUs`)}} />
        </React.Fragment>
    )
}

export default Content;
