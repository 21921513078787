import React, { useCallback } from 'react';

import { useOpenContactModal } from '../../hooks';
import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../constants';

const { MODAL: { CONTACT_US_BUTTON } } = GTM_KEYS;
const { SUPERBOWL_25 } = MODAL_SOURCE_BUTTONS;

import Link from '../common/Link';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';
import Video from '../ui/Video';

const Content = () => {
    const openContactModal = useOpenContactModal(SUPERBOWL_25);

    const onContactUs = useCallback((e) => {
        e.preventDefault();
        openContactModal();
    }, [openContactModal]);


    return (
        <React.Fragment>
            <p>Super Bowl LIX marked a historic moment in video streaming. For the first time ever, <b>OTT streaming outperformed traditional broadcast TV</b> we used for this study in terms of latency and overall performance.</p>
            <p><Link to='/articles/tag-video-system-partnership-end-to-end-video-monitoring-press-release/'>By joining forces</Link>, Witbe’s real-device video monitoring and TAG Video Systems’ real-time data analysis and Content Matching created a groundbreaking end-to-end video monitoring solution that delivered astonishing results.</p>

            <h2>OTT Low-Latency ABR is finally becoming reality at scale: Streaming surpasses legacy cable distribution</h2>
            <p>One of the biggest surprises of this year’s Big Game was the revelation that <b>OTT streaming platforms delivered the action faster than traditional broadcasters we used for this study</b>. Historically, streaming services have lagged behind due to Adaptive Bitrate (ABR) implementation. However, our monitoring showed that select OTT platforms <b>achieved, on average, lower latency than cable feeds—an industry first</b>.</p>
            <p>To interpret our real-time measurements, we categorized streaming platform performance into three distinct latency tiers:</p>
            <ul>
                <li><b>Faster than cable broadcast</b>: An ideal experience—you’re in the heart of the action.</li>
                <li><b>Less than 10 seconds behind cable broadcast: </b>A solid performance, acceptable, unless you live near a noisy sports bar or have loud neighbors spoiling the game.</li>
                <li><b>10+ seconds behind cable broadcast (with some platforms reaching 40+ seconds): </b>A significant delay—viewers may want to disable notifications to avoid spoilers.</li>
            </ul>

            <h3>Key Latency Findings</h3>
            <img src='/articles/2025-02-11-superbowl/superbowl-article-content-graph.jpg' alt='Content graph illustrating key Super Bowl LIX streaming performance findings, including latency comparisons and platform insights found by Witbe and TAG joint monitoring session.' />

            <h3>Key takeaways on top performers:</h3>
            <ul>
                <li><b>Tubi outperformed cable broadcast on multiple devices.</b> According to our tests, the closest to real-time large-screen experience was delivered by<b> Tubi on Fire TV, streaming 2.6 seconds ahead of Spectrum</b>, chosen as the broadcast time reference for our study. <br />Additionally, during the event, Tubi on iOS over 5G was an additional second ahead. However, since our study focused on large-screen performance, this result was not included in the overall findings.<br /><br /></li>
                <li><b>Tubi on Apple TV</b> showed slightly more delay, likely due to differences in streaming protocols, or packager configuration choices, with <b>MPEG-DASH potentially offering an advantage over HLS</b>.<br /><br /></li>
                <li><b>Tubi on Samsung TV did not achieve real-time performance</b>, highlighting <b>discrepancies in application behavior across different devices and operating systems</b>. As native smart TV applications gain more users, <Link to='/tech-rewind-2024/#scale'>more Witbe customers are prioritizing testing on these platforms</Link>, which were historically considered lower priority. However, this has changed significantly in the past two years.</li>
            </ul>

            <p>This analysis underscores the <b>significant variation in latency across distributors</b>, reinforcing the <b>need for real-time monitoring and optimization</b> to ensure the best live-streaming experience.</p>
            <p><Link to='/contact-us/' onClick={onContactUs} data-gtm-id={`${CONTACT_US_BUTTON}-${SUPERBOWL_25}`}>Contact us</Link> to receive the full comprehensive results.</p>

            <h2>How did we measure real user streaming Latency During the Superbowl?</h2>
            <p>Ensuring seamless, low-latency video streaming at scale is a major challenge. Our success in monitoring this large scale live sport event came down to four key factors:</p>
            <ol>
                <li><b>Real-Device Monitoring</b> – Witbe's technology tests video streams on actual testing consumer devices (smart TVs, mobile apps, streaming sticks) rather than emulated environments. This approach provides the <b>most accurate representation of what end users experience at the edge of the video service delivery path.</b><br /><br /></li>
                <li><b>Automated Latency Measurement</b> – By partnering with TAG Video Systems, we leveraged their advanced real-time Content Matching technology to <b>automatically measure true latency</b> from reference point to screen.<br /><br /></li>
                <li><b>Virtual NOC (Network Operations Center)</b> – <Link to='/articles/what-is-a-virtual-noc/'>Witbe’s Virtual NOC</Link> enabled comprehensive remote monitoring, allowing us to detect and analyze performance differences across platforms and geographic locations.<br /><br /></li>
                <li>Fully Automated Testing – <b>All the results showcased in this article were measured automatically with off-the-shelf Witbe and TAG Video Systems products</b>. (No manual intervention was required—no human was asked to use a stopwatch while streaming :) and not relying on vendor-specific metadata).</li>
            </ol>

            <p>We also captured <b>video recordings and performance analytics in Smartgate, our observability platform </b>providing unparalleled insights into how the game was streamed across different services.</p>

            <h3>Tested Platforms and Devices</h3>

            <p>To measure latency across both traditional and streaming providers, we tested widely used large-screen ("lean-back") devices across multiple locations in North America covering traditional cable TV providers and satellite broadcasters, and virtual MVPD (vMVPD) / pure streaming platforms.</p>

            <h4>Virtual MVPD / Pure Streaming Platforms:</h4>
            <ul>
                <li>Tubi – Tested on AppleTV, Fire TV and Samsung TV</li>
                <li>Youtube TV – Tested on Fire TV, Apple TV and Roku</li>
                <li>DAZN – Tested in on Fire TV</li>
                <li>Fubo TV – Tested on Fire TV and Apple TV</li>
            </ul>

            <h4>Traditionnal DTV (Cable TV, Satellite, …):</h4>
            <ul>
                <li>Spectrum</li>
                <li>Verizon</li>
                <li>Direct TV Stream</li>
                <li>Bell Fibe</li>
                <li>DVB-S Satellite STB</li>
            </ul>

            <p>All streams were launched in 4K when available and all devices used were 4K capable.</p>

            <Video
                isDecorative
                video='/articles/2025-02-11-superbowl/virtual-noc-half-time.mp4'
                aria-label="Video recording of the Virtual NOC during the Super Bowl halftime show, monitoring network performance and latency."
                className={newSnapshots.snapshotsVideoContainer}
            />
            <p/>
            <Video
                isDecorative
                video='/articles/2025-02-11-superbowl/tag-witbe-e2e-latency.mp4'
                aria-label="A video showing TAG and Witbe’s end-to-end latency monitoring solution, showcasing real-time streaming performance analysis for Super Bowl LIX across multiple platforms."
                className={newSnapshots.snapshotsVideoContainer}
            />

            <h2>Live Event Quality and Availability Performance Analysis</h2>
            <p>Beyond latency, our monitoring uncovered key insights about <b>quality and service availability</b> during the Super Bowl.</p>
            <p>The only notable performance issues occurred at the start of the halftime show, which represents the peak in viewership—both for sports fans and casual viewers. However, there were no major service disruptions.</p>
            <p>We observed minor performance degradations during this peak period, with examples below for Fubo TV (latency increasing from an average of 3 seconds to 5.5 seconds) and Tubi.</p>
            <p>Congratulations to all technical teams for successfully delivering a smooth streaming experience during one of the most-watched live events of the year.</p>

            <img src='/articles/2025-02-11-superbowl/fubo-tv-time-to-first-frame-automated-measurement.png' alt="Measurement chart of Fubo TV’s time to first frame, assessing its startup speed during the Super Bowl broadcast in Witbe's smartgate dashboard." />
            <p />
            <img src='/articles/2025-02-11-superbowl/tubi-performance-loading-time.png' alt='Graph displaying Tubi’s performance in terms of loading time during Super Bowl streaming.' />

            <p><br />Both DAZN and Tubi on Samsung TV experienced some failed loading issues during the halftime show. These disruptions occurred during peak viewership, highlighting the challenges of handling high traffic volumes.</p>

            <video className={newSnapshots.snapshotsVideoContainer} controls aria-label="Video showcasing Tubi’s stream loading experience on a Samsung TV, highlighting loading issue.">
                <source src='/articles/2025-02-11-superbowl/tubi-samsung-tv-error-loading-stream.mp4' type='video/mp4' />
            </video>
            <p />
            <img src='/articles/2025-02-11-superbowl/dazn-error-loading-firetv.png' alt="Screenshot of Witbe's Smartgate dashboard showing a loading error on DAZN while streaming on Fire TV during Super Bowl LIX, illustrating platform-specific streaming issues." />

            <p><br />The errors and performance degradations we measured once again highlight the importance of testing on real user devices to gain a comprehensive view of the services delivered.</p>

            <h2>The Future of Live Event Streaming</h2>
            <p>Our monitoring success during Super Bowl LIX sets a new industry standard for live event streaming.</p>
            <p>The implementation of low-latency versions of HLS and MPEG-DASH has been a major industry focus, aiming to bridge the latency gap between traditional DTV and ABR streaming. Today, we can see these advancements live on select platforms and applications. This introduces new risks, and sets new requirements for testing and monitoring strategies to ensure consistent performance.</p>

            <h2>Take on the Challenge with Witbe &amp; TAG</h2>
            <p>
                Want to learn how Witbe and TAG can help you tackle the challenges of live event streaming?<br />
                <Link to='/contact-us/' onClick={onContactUs} data-gtm-id={`${CONTACT_US_BUTTON}-${SUPERBOWL_25}`}>Contact us</Link> for a demo and see how our solutions can optimize your video delivery.
            </p>


            <h3>Behind the Scenes</h3>
            <p>A huge thank you to all the team members from <a href='https://tagvs.com/' rel='external' target='_blank'>TAG Video Systems</a> and <b>Witbe</b> who contributed to this joint event. Your expertise and dedication made this groundbreaking monitoring possible!</p>

            <figure>
                <img src='/articles/2025-02-11-superbowl/tag-multiviewer-latency.jpg' alt="A laptop screen on a plane displaying the TAG multiviewer interface, monitoring real-time streaming latency during Super Bowl LIX powered by Witbe’s real-device monitoring technology." />
            </figure>
            <figure>
                <img src='/articles/2025-02-11-superbowl/superbowl-coin-toss-witbe-virtual-noc.jpg' alt="Image depicting the transition from the Super Bowl coin toss to Witbe's Virtual NOC monitoring interface, highlighting real-time analysis." />
            </figure>
        </React.Fragment>
    )
};

export default Content;
