import React from 'react';

const Content = () => (
    <React.Fragment>
        <h2>
            {'Witbe\'s '}
            <a href='/articles/witbe-ad-monitoring-content-matching-best-video-advertising-solution-2023/' target='_blank' alt='Ad Monitoring and Matching technology'>
                Ad Monitoring and Matching technology
            </a>
            {' was recognized at the '}
            <a href='https://www.csimagazine.com/awards/winners24.php' target='_blank' alt='CSI Awards 2024' rel='noreferrer'>CSI Awards 2024</a>
            {' in a ceremony at '}
            <a href='/events/ibc-2024/' target='_blank' alt='IBC2024'>IBC2024</a>
            .
        </h2>
        <p>
            {'The CSI Awards "recognize and reward innovation and excellence in the cable, satellite, broadcast, IPTV, telco, broadband/OTT video, and mobile TV sectors," '}
            {'according to their official description, which lists them "among the most prestigious and competitive technology awards in the industry". '}
            {'Ad Monitoring and Matching won in the "Best Advance in Ad Tech or Monetization Technology" category.'}
        </p>
        <h2>Award-Winning Ad Tech for Dynamic Ad Insertion Monitoring</h2>
        <p>
            {'Witbe\'s Ad Monitoring and Matching technology helps video service providers identify streaming errors caused by Dynamic Ad Insertion. It tests and monitors streams on '}
            <a href='/technology/' target='_blank' alt='real, physical devices'>real, physical devices</a>
            {', the only way to accurately verify errors that viewers are experiencing at home. '}
            {'The technology records Key Performance Indicators for video service providers, including whether the ad plays, the video quality and audio loudness of the ad compared with the content, and the amount of buffering before and during the ad. '}
            {'The technology also offers service providers the ability to access and share video recordings of their dynamic ad insertion sequence.'}
        </p>
        <figure>
            <img src='/articles/2024-10-02-csi-award/csi-asset-articles-content-landscape.jpg' alt='CSI awards 2024 winner' />
        </figure>
        <h2>Key Performance Indicators for Ad Monitoring on Real Devices</h2>
        <p>
            As Witbe noted in their entry, "
            <a href='/articles/ad-monitoring-nab-product-of-the-year-award-2024/' target='_blank' alt='Ad Monitoring and Matching'>Ad Monitoring and Matching</a>
            {' was developed to address common and critical issues with Dynamic Ad Insertion, '}
            {' a process that has become even more widespread with the recent rise of Free Ad-Supported Streaming Television (FAST) channels and the proliferation of streaming sports and ad-supported online video.'}
        </p>
        <p>
            {'Witbe has observed that up to 30% of all online viewing sessions encounter an ad error caused by Dynamic Ad Insertion. Resolving these errors is critical for video service providers who want to retain'}
            {' viewers, build brand awareness, and deliver robust ad impressions to third-party advertisers."'}
        </p>
        <h2>Ad Monitoring and Matching: Enhancing Viewer Experience and Ad Impressions</h2>
        <p>
            {'Witbe\'s Ad Monitoring and Matching technology is available now.'}
            {' It is essential to delivering a superior ad-supported viewing Quality of Experience for all video service providers working with Dynamic Ad Insertion.'}
            {' We were excited to be recognized by the CSI Awards for the best new ad technology in the industry.'}
        </p>
        <p>
            {'To learn more about how Witbe\'s technology can help your team, '}
            <a href='/contact-us/' target='_blank' alt='contact us today'>contact us today</a>
            !
        </p>
    </React.Fragment>
);

export default Content;
