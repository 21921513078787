import React from 'react';
import { useTranslation } from 'react-i18next';

import monitoring from '../../images/resources/Screenshot22.png';
import singleInterface from '../../images/resources/Screenshot20.png';
import alerting from '../../images/resources/Screenshot21.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <h2>{t(`siteMetadata.resources.articles.mobileVirtual.h2`)}</h2>
            <figure>
                <img src='/articles/old/MVNO_QoE_Monitoring.jpg' id={newSnapshots.mainImg} alt='Witbe Smartgate' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.mobileVirtual.mainText1`)}</p>
            <p>{t(`siteMetadata.resources.articles.mobileVirtual.mainText2`)}</p>

            <h3>{t(`siteMetadata.resources.articles.mobileVirtual.sectionATitle`)}</h3>
            <figure>
                <img src={monitoring} alt='Witbe Smartgate' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.mobileVirtual.sectionAContent1`)}</p>
            <p>{t(`siteMetadata.resources.articles.mobileVirtual.sectionAContent2`)}</p>

            <h3>{t(`siteMetadata.resources.articles.mobileVirtual.sectionBTitle`)}</h3>
            <figure>
                <img src={singleInterface} alt='Witbe Smartgate' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.mobileVirtual.sectionBContent1`)}</p>

            <h3>{t(`siteMetadata.resources.articles.mobileVirtual.sectionCTitle`)}</h3>
            <figure>
                <img src={alerting} alt='Witbe Smartgate' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.mobileVirtual.sectionCContent1`)}</p>
        </React.Fragment>
    )
}

export default Content;
